import { Component, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Student } from 'src/app/pages/students/interfaces/student.interface';
import { TableProgramTagComponent } from '../../../components/table-program-tag/table-program-tag.component';
import { ConfirmationService } from 'src/app/services/confirmation/confirmation.service';
import { StudentsListService } from './students-list.service';
import { ClientsListService } from '../clients-list/clients-list.service';
import { UsersHelperService } from '../../../users-helper.service';
import { isEmpty } from 'lodash';

@Component({
  selector: 'users-students-list',
  templateUrl: './students-list.component.html',
  styleUrls: ['./students-list.component.scss'],
})
export class StudentsListComponent {
  template: 'unselected' | 'loading' | 'loaded' | 'noStudents' = 'unselected';

  dataSource: MatTableDataSource<any>;

  currentClientId: string;

  @ViewChild(TableProgramTagComponent) programTagComponent: TableProgramTagComponent;

  constructor(
    private _studentsListService: StudentsListService,
    private _clientsListService: ClientsListService,
    private _confirm: ConfirmationService,
    private _usersHelperService: UsersHelperService,
  ) {}

  async getStudents(id: string): Promise<void> {
    // Check if current client is not already selected
    if (this.currentClientId !== id) {
      // Set template
      this.template = 'loading';

      // Save current client id
      this.currentClientId = id;

      // Get students
      const students = await this._studentsListService.showStudents(id, { refresh: true });

      // Check if client has students
      students.length === 0 ? (this.template = 'noStudents') : (this.template = 'loaded');

      // Update datasource
      this.dataSource = new MatTableDataSource(students);
    }
  }

  public editClientStudent(student: Student): void {
    this._studentsListService.editStudent(student);
  }

  public accessNeuralign(student: Student): void {
    this._usersHelperService.goToStudentProgramPage(student);
  }

  public async deleteClientStudent(student: Student): Promise<void> {
    const studentHasAssignedCredit = student.tokens?.length > 0;
    const studentHasProgress = student.progress.length > 0;

    if (studentHasAssignedCredit && !studentHasProgress) {
      this._confirm.createConfirmation(
        'Warning',
        'The assigned credit should be retrieved by clicking on the - sign before archiving the student.',
        'Close',
        undefined,
      );
    } else {
      this._confirm
        .createConfirmation(
          'Warning',
          `Are you sure you want to delete ${!isEmpty(student.fullname) ? student.fullname : 'this student'}?`,
          'Yes',
          'No',
          '350px',
        )
        .then(async () => {
          this.template = 'loading';
          await this._studentsListService.archiveStudent(student);

          const students = await this._studentsListService.showStudents(this.currentClientId, { refresh: true });

          students.length === 0 ? (this.template = 'noStudents') : (this.template = 'loaded');

          this.dataSource = new MatTableDataSource(students);

          students.length === 0 ? (this.template = 'noStudents') : (this.template = 'loaded');
        });
    }
  }

  public applyFilter(event: any): void {
    let { value } = event.target;
    value = value.trim();
    value = value.toLowerCase();
    this.dataSource.filter = value;
  }

  public handleAddStudent() {
    this._clientsListService.addClientStudent(this.currentClientId);
  }

  public canAccessNeuralign(student: Student): boolean {
    // a student should only be able to access Neuralign
    // if they have a credit spent on their enrollment
    return student.tokens?.length > 0;
  }
}
