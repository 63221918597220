import { Component, Inject, OnInit } from '@angular/core';
import { RestAPIService } from 'src/app/services/rest/rest-api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmationService } from 'src/app/services/confirmation/confirmation.service';
import { Account, Organization } from 'src/app/shared/models';
import { sortBy, get, isEmpty, includes } from 'lodash';
import { MatTableDataSource } from '@angular/material/table';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Course } from 'src/app/pages/configuration-pages/interfaces/global-config.interfaces';
import { IconDefinition, faSpinner } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-allowed-users-dialog.component',
  templateUrl: './allowed-users-dialog.component.html',
  styleUrls: ['./allowed-users-dialog.component.scss'],
})
export class AllowedUsersDialog implements OnInit {
  public loading = true;
  public organizationList: Organization[] = [];
  public dataSource: MatTableDataSource<Organization>;
  public spinner: IconDefinition = faSpinner;
  public displayedColumns: string[] = ['name', 'enabled'];
  public checked = true;
  public unchecked = false;
  public allowedUsers = false;

  constructor(
    public rest: RestAPIService,
    public snack: MatSnackBar,
    public confirm: ConfirmationService,
    public dialogRef: MatDialogRef<AllowedUsersDialog>,
    @Inject(MAT_DIALOG_DATA) public data: Course,
  ) {}

  async ngOnInit() {
    await this.getOrganizationsList();
    this.dataSource = new MatTableDataSource(this.organizationList);
  }

  public close() {
    this.dialogRef.close();
  }

  public save() {
    this.dialogRef.close(true);
  }

  public async getOrganizationsList() {
    const users = await this.rest.get('organization/all', {
      msg: 'Could not get organization list.',
    });

    this.organizationList = sortBy(users, (u: Account) => get(u, 'organization.name'))
      .map((acc: Account) => acc.organization)
      .filter((u: Organization) => !isEmpty(get(u, 'name')));

    this.loading = false;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    this.dataSource.filterPredicate = (data, filter: string): boolean => {
      return data ? data.name.toLowerCase().includes(filter) : data.name.toLowerCase().includes(filter);
    };
  }

  public checkEnabledOrgs(organization: Organization): boolean {
    const organizationArray = this.data.allowedOrganizations || [];
    const findOrg = organizationArray.find((o) => o === organization.id);

    return findOrg ? true : false;
  }

  public addEnabledOrg(organization: Organization) {
    const organizationArray = this.data.allowedOrganizations || [];

    const findOrg = organizationArray.find((o) => o === organization.id);

    if (findOrg) {
      const index = organizationArray.indexOf(organization.id);
      organizationArray.splice(index, 1);
    } else {
      organizationArray.push(organization.id);
    }

    this.data.allowedOrganizations = organizationArray;
  }

  public selectAllUsers() {
    const allUsers = this.organizationList.map((o) => o.id);

    this.data.allowedOrganizations = allUsers;
  }

  public reverseSelection() {
    const organizationArray = this.data.allowedOrganizations || [];
    const allUsers = this.organizationList.map((o) => o.id);

    if (organizationArray.length === 0) {
      return;
    } else {
      const filteredUsers = allUsers.filter((id) => !includes(this.data.allowedOrganizations, id));
      this.data.allowedOrganizations = filteredUsers;
    }
  }

  public showAllowedUsers() {
    if (this.allowedUsers) {
      const newArray = this.organizationList.filter((o) => this.checkEnabledOrgs(o));
      const newOrgList = [].concat(newArray);

      this.dataSource = new MatTableDataSource(newOrgList);
    } else {
      this.dataSource = new MatTableDataSource(this.organizationList);
    }
  }
}
