<div class="main">
  <section class="container">
    <div class="title">
      <h2 *ngIf="typeHttp === 'POST'">Tax Registration</h2>
      <h2 *ngIf="typeHttp === 'PUT'">Tax Update</h2>
      <h2 *ngIf="type === 'view' && typeHttp === 'GET'">Tax Visualization</h2>
    </div>

    <form class="form">
      <div class="input-group">
        <div class="input-block">
          <label for="taxType">Tax Type *</label>
          <select
            [disabled]="type === 'view'"
            [(ngModel)]="taxType"
            name="taxType"
          >
            <option
              value=""
              disabled
              selected
            >
              Sales Tax
            </option>
            <option value="VAT">VAT</option>
            <option value="GST">GST</option>
            <option value="custom">custom</option>
          </select>
        </div>
        <div class="input-block">
          <div *ngIf="taxType === 'custom'">
            <label for="taxCustomType">Insert custom tax name *</label>
            <input
              [disabled]="type === 'view'"
              name="taxCustomType"
              type="text"
              [(ngModel)]="taxCustomType"
              placeholder="Insert custom tax name"
            />
          </div>
        </div>
      </div>
      <div class="input-group">
        <div class="input-block">
          <label for="taxCountry">Select the country *</label>
          <select
            [disabled]="typeHttp === 'PUT' || type === 'view'"
            [(ngModel)]="taxCountry"
            name="taxCountry"
          >
            <option
              value=""
              disabled
              selected
            >
              Select the country
            </option>
            <option
              *ngFor="let country of countries"
              [value]="country.acronym"
            >
              {{ country.name }}
            </option>
          </select>
        </div>
        <div class="input-block">
          <div *ngIf="taxCountry === 'CA'">
            <label for="taxState">Select the state *</label>
            <select
              [disabled]="typeHttp === 'PUT' || type === 'view'"
              [(ngModel)]="taxState"
              name="taxState"
            >
              <option
                value=""
                disabled
                selected
              >
                Select the state
              </option>
              <option
                *ngFor="let state of canadianStates"
                [value]="state.acronym"
              >
                {{ state.name }}
              </option>
            </select>
          </div>
          <div *ngIf="taxCountry === 'US'">
            <label for="taxState">Select the state *</label>
            <select
              [disabled]="typeHttp === 'PUT' || type === 'view'"
              [(ngModel)]="taxState"
              name="taxState"
            >
              <option
                value=""
                disabled
                selected
              >
                Select the state
              </option>
              <option
                *ngFor="let state of usStates"
                [value]="state.acronym"
              >
                {{ state.name }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="input-group">
        <div class="input-block">
          <label for="taxPercentage">Percentage *</label>
          <input
            [disabled]="typeHttp === 'PUT' || type === 'view'"
            type="text"
            [(ngModel)]="taxPercentage"
            name="taxPercentage"
            placeholder="Insert the tax percentage"
          />
        </div>
        <div class="input-block">
          <label for="taxJurisdiction">Jurisdiction</label>
          <input
            [disabled]="typeHttp === 'PUT' || type === 'view'"
            type="text"
            [(ngModel)]="taxJurisdiction"
            name="taxJurisdiction"
            placeholder="Insert the tax jurisdiction"
          />
        </div>
      </div>
      <div class="input-block-select">
        <label for="taxInclusive">Inclusive tax in price *</label>
        <select
          [disabled]="typeHttp === 'PUT' || type === 'view'"
          [(ngModel)]="taxInclusive"
          name="taxInclusive"
        >
          <option value="false">No (exclusive)</option>
          <option value="true">Yes (inclusive)</option>
        </select>
      </div>
      <div class="input-block">
        <label for="taxDescription">Description</label>
        <textarea
          [disabled]="type === 'view'"
          [(ngModel)]="taxDescription"
          name="taxDescription"
          placeholder="Insert the tax description"
        ></textarea>
      </div>

      <div class="button-group">
        <button
          class="btn-secondary"
          (click)="close()"
        >
          Close
        </button>
        <button
          class="btn-primary"
          *ngIf="type !== 'view'"
          (click)="save()"
        >
          Save
        </button>
      </div>
    </form>
  </section>
</div>
