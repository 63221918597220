import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../services/auth/auth.service';
import { get } from 'lodash';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { User } from '../../models/user.model';
import { AuthenticationTypes } from 'src/app/services/auth/auth-constants';

@Component({
  selector: 'app-callback',
  templateUrl: './call-back.component.html',
  styleUrls: ['./call-back.component.scss'],
})
export class AuthCallbackComponent implements OnInit {
  public kickOutInterval: ReturnType<typeof setTimeout>;

  constructor(
    private _router: Router,
    private authService: AuthService,
    private route: ActivatedRoute,
    private http: HttpClient,
  ) {}

  async ngOnInit() {
    // validate the session and redirect the user

    this.route.queryParams.subscribe(async (params) => {
      clearTimeout(this.kickOutInterval);
      this.kickOutInterval = undefined;

      const verifyType = get(params, 'verify_type', undefined);
      const type = get(params, 'type', undefined);
      const subdomain = get(params, 'subdomain', undefined);
      const email = get(params, 'email', undefined);

      if (this.shouldKickOut(params)) {
        this.kickOutInterval = setTimeout(() => this._router.navigate(['']), 2000);
        return;
      }

      // Check if we're trying to validate the session for a regular sign up or if we're activating a b2c sign up
      if (this.shouldActivateOrgPortalLogin(params)) {
        this.activateLogin();
      } else if (this.shouldValidateSession(params)) {
        await this.authService.validateAuth0Session(verifyType, type);
      } else if (this.shouldActivateB2cSignup(params)) {
        this.activateB2cSignup(subdomain, email);
      }
    });
  }

  public shouldKickOut(params) {
    return (
      !this.shouldValidateSession(params) &&
      !this.shouldActivateB2cSignup(params) &&
      !this.shouldActivateOrgPortalLogin(params)
    );
  }

  public shouldActivateOrgPortalLogin(params) {
    return get(params, 'orgPortal', undefined) !== undefined;
  }

  public shouldValidateSession(params) {
    return (
      get(params, 'type', undefined) === AuthenticationTypes.LOGIN ||
      get(params, 'type', undefined) === AuthenticationTypes.SIGNUP
    );
  }

  public shouldActivateB2cSignup(params) {
    return get(params, 'subdomain', undefined) !== undefined && get(params, 'b2csignup', undefined) !== undefined;
  }

  activateLogin() {
    this.authService.show();
  }

  activateB2cSignup(subdomain: string, email: string) {
    if (subdomain) {
      this.http
        .get(environment.API_URL + '/organization/' + subdomain, {
          params: new HttpParams().set('errorType', 'hidden'),
        })
        .toPromise()
        .then((response: User) => {
          if (response) {
            const org = response.organization;
            this.authService.triggerB2cSignUp(org, email);
          }
        });
    }
  }
}
