import { Category } from '../../configuration-pages/interfaces/global-config.interfaces';

export const gameOptions = [
  { name: 'Cognitive Therapy', label: 'cognitiveTherapyDemo' },
  { name: 'Speed Reading', label: 'speedReadingDemo' },
  { name: 'Reading Exercises', label: 'readingExercisesDemo' },
  { name: 'Mouse Training', label: 'gumball' },
];

export const gameImages = [
  { path: '../../../assets/img/programs/CogTrain_Button_Unlocked.png', label: 'cognitiveTherapyDemo' },
  { path: '../../../assets/img/programs/SpeedRead_Button_Unlocked.png', label: 'speedReadingDemo' },
  { path: '../../../assets/img/programs/ReadExercises_Button_Unlocked.png', label: 'readingExercisesDemo' },
];

export const gameLevels = [
  { level: 'JR', assessmentLevel: 0 },
  { level: 'P1', assessmentLevel: 1 },
  { level: 'P2', assessmentLevel: 2 },
  { level: 'P3', assessmentLevel: 3 },
  { level: 'P4', assessmentLevel: 4 },
];

export const excludedDemoExercises = ['assessment'];

export const gumball: Partial<Category> = {
  name: 'Mouse Training',
  title: 'gumball',
};

export enum GameTag {
  CognitiveTherapy = 'cognitive therapy',
  ReadingExercises = 'reading exercises',
  Assessment = 'assessment',
  SpeedReading = 'speed reading',
  Gumball = 'gumball',
  BrainGames = 'Brain Games',
}
