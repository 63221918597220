import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IconDefinition, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { ConfirmationService } from 'src/app/services/confirmation/confirmation.service';
import { RestAPIService } from 'src/app/services/rest/rest-api.service';
import { Organization, User } from 'src/app/shared/models';

interface DialogData {
  userList: User[];
  organization: Organization;
}

@Component({
  selector: 'app-outsider-import-dialog',
  templateUrl: './outsider-import-dialog.html',
  styleUrls: ['./outsider-import-dialog.scss'],
})
export class OutsiderImportDialog implements OnInit {
  public orgId: string = '';
  public orgList: Organization[] = [];
  public transferingOutsiders = false;
  public readonly spinnerIcon: IconDefinition = faSpinner;

  constructor(
    public dialogRef: MatDialogRef<OutsiderImportDialog>,
    public rest: RestAPIService,
    public confirmation: ConfirmationService,
    protected _snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {}

  async ngOnInit() {
    this.setupOrgList();
  }

  public setupOrgList() {
    this.orgList = this.data.userList.map((user) => user.organization);
    this.orgList = this.orgList.filter((org) => org.name);
    this.orgList.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
  }

  public close() {
    this.dialogRef.close();
  }

  public confirm() {
    const orgToTransfer = this.orgList.find((o) => o.id === this.orgId);
    this.confirmation
      .createConfirmation(
        'Warning',
        `This will transfer the outsiders from ${orgToTransfer.name} to ${this.data.organization.name}, do you really want to proceed?`,
        'Yes',
        'No',
      )
      .then(
        async () => {
          this.transferingOutsiders = true;
          await this.rest.put(
            'admin/organization/transferOutsiders/from/' + orgToTransfer.id + '/to/' + this.data.organization.id,
            {},
          );

          this._snackBar.open(`${orgToTransfer.name} outsiders transfered to ${this.data.organization.name}`, 'Close', {
            horizontalPosition: 'center',
            verticalPosition: 'top',
          });

          this.transferingOutsiders = false;

          this.close();
        },
        () => {
          this.transferingOutsiders = false;

          this._snackBar.open(`Outsiders transfer failed! Please try again`, 'Close', {
            horizontalPosition: 'center',
            verticalPosition: 'top',
          });
        },
      );
  }
}
