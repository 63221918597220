import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { RestAPIService } from 'src/app/services/rest/rest-api.service';
import { StripePrice } from 'src/app/shared/interfaces/Stripe.interfaces';
import { StripeObservableService } from '../stripe-observable.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-price-stripe',
  templateUrl: './price-stripe.component.html',
  styleUrls: ['./price-stripe.component.scss'],
})
export class PriceStripeComponent implements OnInit {
  public prices: StripePrice[];
  public originalPrices: StripePrice[];

  enabled: boolean;
  searchTerm: string;
  public routeApi = 'stripe/price';

  constructor(
    private _rest: RestAPIService,
    public dialog: MatDialog,
    public _stripeService: StripeObservableService,
    public _snackBar: MatSnackBar,
  ) {}

  async ngOnInit() {
    await this.getPricesWithProduct();
    this.originalPrices = Array.isArray(this.prices) ? [...this.prices] : [];
    this._stripeService.currentPrice.subscribe(async (newPrice) => {
      if (newPrice) {
        await this.handleTablePrices(newPrice);
      }
    });
    this.search();
  }

  async handleTablePrices(newPrice: StripePrice) {
    if (newPrice.active === false) {
      this.prices = this.prices.filter((price: StripePrice) => price.id !== newPrice.id);
    } else {
      const createdPrice = await this.insertProductInPrice(newPrice);
      const existingPriceIndex = this.prices.findIndex((price: StripePrice) => price.id === newPrice.id);

      if (existingPriceIndex !== -1) {
        this.prices[existingPriceIndex] = newPrice;
      } else {
        this.prices.push(createdPrice);
      }
    }

    this.originalPrices = [...this.prices];

    this.search();
  }

  public async getPricesWithProduct() {
    try {
      const prices = await this._rest.get(this.routeApi + '/active');
      await Promise.all(
        prices.map(async (price) => {
          return await this.insertProductInPrice(price);
        }),
      );
      const filteredPrices = prices.filter((price) => price.product !== null);
      if (filteredPrices.length > 0) {
        this.enabled = true;
      }
      this.prices = prices;
    } catch (error) {
      this._snackBar.open(`${error.message}!`, 'Close', {
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
    }
  }

  public async insertProductInPrice(price) {
    if (!price.product) return price;
    const product = await this._rest.get('stripe/product/id/' + price.product);
    if (product) {
      price.product_name = product.name;
    }
    return price;
  }

  public search() {
    this.prices = this.handlePriceSearch(this.originalPrices, this.searchTerm);
  }

  public handlePriceSearch(items: StripePrice[], searchTerm: string): StripePrice[] {
    if (!items) return [];
    if (!searchTerm) return items;

    searchTerm = searchTerm.toLowerCase();

    return items.filter((it) => {
      return (
        (it.type ? it.type.toLowerCase().includes(searchTerm) : false) ||
        (it.currency ? it.currency.toLowerCase().includes(searchTerm) : false) ||
        (it.unit_amount ? it.unit_amount.toString().includes(searchTerm) : false) ||
        (it.active ? it.active.toString().includes(searchTerm) : false) ||
        (it.recurring?.interval ? it.recurring?.interval.toString().includes(searchTerm) : false) ||
        (it.metadata?.tokens ? it.metadata?.tokens.toString().includes(searchTerm) : false) ||
        (it.nickname ? it.nickname.toLowerCase().includes(searchTerm) : false)
      );
    });
  }
}
