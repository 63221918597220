import { get } from 'lodash';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { ExcelService } from 'src/app/services/excel/excel.service';
import { RestAPIService } from 'src/app/services/rest/rest-api.service';
import { LoadingService } from 'src/app/services/utils/loading.service';
import { StripeRedirect } from 'src/app/shared/interfaces/Stripe.interfaces';

@Component({
  selector: 'users-organizations-menu',
  templateUrl: './organizations-menu.component.html',
  styleUrls: ['./organizations-menu.component.scss'],
})
export class OrganizationsMenuComponent implements OnInit {
  @Output() setLoading = new EventEmitter();
  constructor(
    private router: Router,
    private excel: ExcelService,
    private _rest: RestAPIService,
    private _snackBar: MatSnackBar,
    private loading: LoadingService,
  ) {}

  @Input() public userHasStripeAccount: boolean = true;

  ngOnInit(): void {}

  adjustDefaultPrice() {
    this.router.navigate(['admin-pricing']);
  }

  async generateStripeAccount() {
    this.loading.ActivateLoading();
    const res: StripeRedirect = await this._rest.post('account/linkStripe', {
      baseUrl: window.location.href,
    });

    const stripeURL = res.redirectUrl;
    window.open(stripeURL, '_self');
  }

  async exportOrganizations() {
    this.setLoading.emit(true);

    const response = await this._rest.get(`/organization/self/outsiders`);
    const outsiders = response?.outsiders ?? [];

    if (outsiders.length === 0) {
      this.setLoading.emit(false);
      this._snackBar.open(`There are no organizations to be exported`, 'Close', {
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
      return;
    }

    const formattedOutsiders = outsiders.map((outsider) => ({
      name: get(outsider, 'organization.name', ''),
      email: get(outsider, 'organization.email', ''),
      'Postal/Zip code': get(outsider, 'organization.address.postalCode', ''),
    }));

    const reportName = `Organizations_${new Date().toLocaleDateString()}`;
    this.excel.exportAsExcelFile(formattedOutsiders, reportName);
    this.setLoading.emit(false);
  }
}
