import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Region } from 'src/app/core/openapi';
import { Organization } from 'src/app/shared/models';
import { RestAPIService } from 'src/app/services/rest/rest-api.service';
import { RegionControllerService } from 'src/app/core/openapi';
import { firstValueFrom } from 'rxjs';

interface DialogData {
  orgList: Organization[];
  region: Region;
}

@Component({
  selector: 'app-region-edit',
  templateUrl: './region-edit-dialog.component.html',
  styleUrls: ['./region-edit-dialog.component.scss'],
})
export class RegionEditDialog implements OnInit {
  constructor(
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<RegionEditDialog>,
    public dialog: MatDialog,
    private rest: RestAPIService,
    private _regionService: RegionControllerService,
  ) {}

  public editingRegion = false;

  public region: Region = {
    name: '',
    email: '',
    iso2: '',
    iso3: '',
    orgId: '',
  };

  ngOnInit() {
    this.setRegion();
  }

  public setRegion() {
    if (this.data.region) {
      this.region = {
        name: this.data.region.name,
        email: this.data.region.email ?? this.getOrgEmail(this.data.region.orgId),
        iso2: this.data.region.iso2,
        iso3: this.data.region.iso3,
        orgId: this.data.region.orgId,
      };

      this.editingRegion = true;
    }
  }

  public getOrgName(organization: Organization) {
    const org = this.data.orgList.find((o) => o.id === organization.id);
    return org.name;
  }

  public getOrgEmail(id: string) {
    const org = this.data.orgList.find((o) => o.id === id);

    return org.email;
  }

  public closeDialog() {
    this.dialogRef.close(false);
  }

  public async confirm() {
    if (this.editingRegion) {
      await firstValueFrom(this._regionService.regionControllerUpdate(this.data.region.id, this.region));

      this.snackBar.open(`Done! The ${this.region.iso2} region has been updated!`, 'Close', {
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
    } else {
      await this.rest.post('region', this.region);

      this.snackBar.open(`Done! The ${this.region.iso2} region has been created!`, 'Close', {
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
    }

    this.dialogRef.close(true);
  }
}
