<app-main-layout [breadcrumbs]="[{ name: 'Stripe', url: '/stripe' }]">
  <div class="topnav">
    <span>Prices List</span>
    <div class="actions">
      <input
        type="text"
        placeholder="Search price..."
        [(ngModel)]="searchTerm"
        (input)="search()"
      />
      <app-add-button
        [enable]="enabled"
        [service]="'price'"
        [routeApi]="'/stripe/price'"
        [typeHttp]="'POST'"
      >
      </app-add-button>
    </div>
  </div>

  <body>
    <app-stripe-dashboard
      [columns]="[
        'product_name',
        'nickname',
        'currency',
        'type',
        'recurring',
        'unit_amount',
        'metadata.tokens',
        'active'
      ]"
      [rows]="prices"
      [routeApi]="routeApi"
    ></app-stripe-dashboard>
  </body>
</app-main-layout>
