<div class="table_responsive">
  <span *ngIf="rows && rows.length"
    >Total of {{ rows.length }} {{ rows[0]?.object }}</span
  >
  <table>
    <thead>
      <tr>
        <th
          *ngFor="let column of columns"
          (click)="sortTable(column)"
        >
          {{ getColumnDisplayName(column) | uppercase }}
          <span *ngIf="sortField === column && sortDirection === 'asc'"
            >&#9650;</span
          >
          <span *ngIf="sortField === column && sortDirection === 'desc'"
            >&#9660;</span
          >
        </th>
        <th>ACTIONS</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let row of rows">
        <ng-container *ngIf="rows.length > 0">
          <td *ngFor="let column of columns">
            {{ getColumnValue(row, column) }}
          </td>
        </ng-container>
        <td>
          <div class="action_btn">
            <stripe-table-action-icon
              [icon]="'eye'"
              [tooltip]="'View'"
              [stripeDataModal]="getStripeDataModal(row, 'GET', 'view')"
              [routeApi]="routeApi"
            ></stripe-table-action-icon>
            <stripe-table-action-icon
              [icon]="'edit'"
              [tooltip]="'Edit item'"
              [stripeDataModal]="getStripeDataModal(row, 'PUT')"
              [routeApi]="routeApi"
            ></stripe-table-action-icon>
            <stripe-table-action-icon
              [disableHandleClick]="true"
              (click)="deleteItem(row)"
              [routeApi]="routeApi"
              [icon]="'trash'"
              [tooltip]="'Delete item'"
            ></stripe-table-action-icon>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>
