<header>
  <img
    src="assets/img/NeurAlign text logo.png"
    width="100%"
  />
</header>

<iframe
  width="100%"
  [src]="data | safeUrl"
  allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
  allowfullscreen
>
</iframe>

<button (click)="closeModal()">x</button>
