<ng-container *ngIf="template === 'table'">
  <users-table-header heading="Admins"></users-table-header>

  <users-table-search-input
    placeholder="Search admins..."
    (userKeyUp)="applyFilter($event)"
  ></users-table-search-input>

  <div class="table">
    <mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
        <mat-cell *matCellDef="let admin">
          {{ admin.name }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
        <mat-cell *matCellDef="let admin">
          <users-table-status-item
            [status]="admin.status"
          ></users-table-status-item>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef> Actions </mat-header-cell>
        <mat-cell *matCellDef="let admin">
          <div class="icons">
            <users-table-action-icon
              [icon]="'edit'"
              [tooltip]="'Edit admin'"
              (click)="editAdmin(admin)"
            ></users-table-action-icon>
            <users-table-action-icon
              [icon]="'redo'"
              [tooltip]="getEmailVerifiedTooltip(admin.emailVerified)"
              [disabled]="admin.emailVerified"
              (click)="resendEmailVerification(admin)"
            ></users-table-action-icon>
            <users-table-action-icon
              [icon]="'trash'"
              [tooltip]="'Delete admin'"
              (click)="deleteAdmin(admin)"
            ></users-table-action-icon>
          </div>
        </mat-cell>
      </ng-container>
      <mat-header-row
        *matHeaderRowDef="['name', 'status', 'actions']; sticky: true"
      ></mat-header-row>
      <mat-row
        *matRowDef="let row; columns: ['name', 'status', 'actions']"
      ></mat-row>
    </mat-table>

    <ng-container *ngIf="isDataSourceEmpty()">
      <users-empty-state
        class="no-users"
        text="This organization currently has no admins. You can add an admin by clicking on the Add New button in the top right corner."
        template="noData"
      ></users-empty-state>
    </ng-container>
  </div>
</ng-container>

<ng-container *ngIf="template === 'loading'"
  ><app-loading-indicator></app-loading-indicator
></ng-container>
