import { Router } from '@angular/router';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { StripeObservableService } from '../../stripe-observable.service';
import { RestAPIService } from 'src/app/services/rest/rest-api.service';

import { StripeDataModal, StripeHttpMethod, StripeTax } from 'src/app/shared/interfaces/Stripe.interfaces';
import { ModalStripeService } from '../modals-stripe.service';

@Component({
  selector: 'app-tax-stripe-modal',
  templateUrl: './tax-stripe-modal.component.html',
  styleUrls: ['../modal-stripe.component.scss'],
})
export class TaxStripeModalComponent {
  taxes: StripeTax[];
  taxId: string;

  // Helpers
  public readonly countries: object;
  public readonly canadianStates: object;
  public readonly usStates: object;

  // Modal config
  type: string;
  service: string;
  typeHttp: string;
  routeApi: string;

  //Form tax
  taxType: string;
  taxCustomType: string;
  taxCountry: string;
  taxState: string;
  taxPercentage: number;
  taxJurisdiction: string;
  taxInclusive: boolean;
  taxDescription: string;
  priceFirstPurchase: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: StripeDataModal,
    public dialogRef: MatDialogRef<TaxStripeModalComponent>,
    public route: Router,
    private _stripeService: StripeObservableService,
    private _rest: RestAPIService,
    private _modalStripeService: ModalStripeService,
  ) {
    this.type = data.type;
    this.service = data.service;
    this.routeApi = data.routeApi;
    this.typeHttp = data.typeHttp;

    this.countries = this._modalStripeService.getCountries();
    this.canadianStates = this._modalStripeService.getCanadianStates();
    this.usStates = this._modalStripeService.getUsStates();

    if (data.stripeDataModal) {
      this.initData(data.stripeDataModal.data);
    }
  }

  public close() {
    this.dialogRef.close(true);
  }

  private initData(data: any) {
    if (data.taxType !== 'VAT' && data.taxType !== 'GST') {
      this.taxCustomType = data.taxType;
      this.taxType = 'custom';
    } else {
      this.taxType = data.taxType;
    }
    this.taxId = data.id;
    this.taxCountry = data.taxCountry;
    this.taxState = data.taxState;
    this.taxPercentage = data.taxPercentage;
    this.taxJurisdiction = data.taxJurisdiction;
    this.taxInclusive = data.taxInclusive;
    this.taxDescription = data.taxDescription;
  }

  public async save() {
    if (this.typeHttp === StripeHttpMethod.POST) {
      await this.create();
      this.close();
    } else if (this.typeHttp === StripeHttpMethod.PUT) {
      await this.update();
      this.close();
    }
  }

  private async create() {
    try {
      const tax = {
        display_name: this.taxCustomType ? this.taxCustomType : this.taxType,
        country: this.taxCountry,
        state: this.taxState,
        percentage: this.taxPercentage,
        jurisdiction: this.taxJurisdiction,
        inclusive: this.taxInclusive,
        description: this.taxDescription,
      };
      const createTax = await this._rest.post(this.routeApi, tax);
      this._stripeService.changeTax(createTax);
    } catch (error) {
      throw new Error(error);
    }
  }

  private async update() {
    try {
      const updateTax = await this._rest.put(`${this.routeApi}/id/${this.taxId}`, {
        display_name: this.taxCustomType ? this.taxCustomType : this.taxType,
        country: this.taxCountry,
        jurisdiction: this.taxJurisdiction,
        description: this.taxDescription,
        ...(this.taxState ? { state: this.taxState } : {}),
      });
      this._stripeService.changeTax(updateTax);
    } catch (error) {
      throw new Error(error);
    }
  }
}
