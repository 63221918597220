<div class="main">
  <section class="container">
    <div class="title">
      <h2 *ngIf="typeHttp === 'POST'">Price Registration</h2>
      <h2 *ngIf="typeHttp === 'PUT'">Price Update</h2>
      <h2 *ngIf="type === 'view' && typeHttp === 'GET'">Price Visualization</h2>
    </div>

    <form class="form">
      <div class="input-group">
        <div class="input-block">
          <label for="priceProduct">Select the product</label>
          <select
            [disabled]="type === 'view' || typeHttp === 'PUT'"
            [(ngModel)]="priceProduct"
            name="priceProduct"
          >
            <option
              *ngFor="let product of products"
              [value]="product.id"
            >
              {{ product.name }}
            </option>
          </select>
        </div>
        <div class="input-block">
          <label for="priceNickname">Name</label>
          <input
            [disabled]="type === 'view'"
            name="priceNickname"
            type="text"
            [(ngModel)]="priceNickname"
            placeholder="Insert custom nickname"
          />
        </div>
      </div>
      <div class="input-group">
        <div class="input-block">
          <label for="priceCurrency">Currency *</label>
          <select
            [disabled]="type === 'view' || typeHttp === 'PUT'"
            [(ngModel)]="priceCurrency"
            name="priceCurrency"
          >
            <option
              value=""
              disabled
              selected
            >
              Select the currency
            </option>
            <option value="cad">CAD</option>
            <option value="usd">USD</option>
          </select>
        </div>
        <div class="input-block">
          <label for="priceUnitAmount">Price *</label>
          <input
            [disabled]="type === 'view' || typeHttp === 'PUT'"
            type="number"
            [(ngModel)]="priceUnitAmount"
            name="priceUnitAmount"
            placeholder="Insert the unit amount"
          />
        </div>
      </div>
      <div class="input-group">
        <div class="input-block">
          <label for="priceType">Type *</label>
          <select
            [disabled]="type === 'view' || typeHttp === 'PUT'"
            [(ngModel)]="priceType"
            name="priceType"
          >
            <option
              value=""
              disabled
              selected
            >
              Select the type
            </option>
            <option value="recurring">Recurring</option>
            <option value="one_time">One Time</option>
          </select>
        </div>
        <div class="input-block">
          <div *ngIf="priceType === 'recurring'">
            <label for="priceInterval">Interval *</label>
            <select
              [disabled]="type === 'view' || typeHttp === 'PUT'"
              [(ngModel)]="priceInterval"
              name="priceInterval"
            >
              <option
                value=""
                disabled
                selected
              >
                Select the interval
              </option>
              <option value="day">Day</option>
              <option value="week">Week</option>
              <option value="month">Month</option>
              <option value="year">Year</option>
            </select>
          </div>
        </div>
      </div>
      <div class="input-group">
        <div class="input-block">
          <label for="priceQuantityTokens">Quantity of tokens *</label>
          <input
            [disabled]="type === 'view'"
            type="number"
            [(ngModel)]="priceQuantityTokens"
            name="priceQuantityTokens"
            placeholder="Insert the unit amount"
          />
        </div>
        <div class="input-block">
          <label for="priceActive">Active *</label>
          <select
            [disabled]="type === 'view' || typeHttp === 'PUT'"
            [(ngModel)]="priceActive"
            name="priceActive"
          >
            <option value="true">Enabled</option>
            <option value="false">Disabled</option>
          </select>
        </div>
      </div>
      <div class="input-group">
        <div class="input-block-switch">
          <div class="tooltip">
            <span class="tooltiptext"
              >This will be the price displayed for the first purchase.</span
            >
            <label for="priceFirstPurchase">First Purchase?</label>
          </div>
          <div>
            <input
              [(ngModel)]="priceFirstPurchase"
              name="priceFirstPurchase"
              type="checkbox"
              id="priceFirstPurchase"
              [disabled]="type === 'view'"
            />
            <label
              for="priceFirstPurchase"
              class="switch"
            >
              <span class="slider"></span>
            </label>
          </div>
        </div>
      </div>

      <div class="button-group">
        <button
          class="btn-secondary"
          (click)="close()"
        >
          Close
        </button>
        <button
          class="btn-primary"
          *ngIf="type !== 'view'"
          (click)="save()"
        >
          Save
        </button>
      </div>
    </form>
  </section>
</div>
