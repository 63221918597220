<app-main-layout [breadcrumbs]="[{ name: 'Home', url: '/' }]">
  <div
    class="mainContainer col-8 px-0 m-auto my-3"
    *ngIf="!loading"
  >
    <div class="header col-12 px-0">Neuralign</div>

    <div class="title text-center">Complete this form to continue</div>

    <div class="content d-flex justify-content-center my-3">
      <form
        [formGroup]="orgFormGroup"
        class="w-100"
      >
        <div class="d-flex justify-content-between">
          <div class="logo col-5 px-0">
            <div>
              <h3>Logo</h3>
              <img
                class="my-4"
                [src]="orgFormGroup.get('logo').value"
              />
              <br />
              <app-simple-button
                class="m-auto my-2"
                text="UPLOAD IMAGE"
                (click)="uploadImage()"
              ></app-simple-button>
            </div>
          </div>

          <div class="inputs col-6 px-0">
            <div>
              <mat-form-field
                appearance="outline"
                class="col-12 px-0 registerField"
              >
                <mat-label>Organization name</mat-label>
                <input
                  (blur)="fillSubdomain()"
                  required
                  matInput
                  placeholder="Organization name"
                  formControlName="name"
                />
              </mat-form-field>
            </div>

            <div class="two-fields col-12 px-0">
              <div>
                <mat-form-field
                  appearance="outline"
                  class="px-0 registerField"
                >
                  <mat-label>Profession</mat-label>
                  <input
                    matInput
                    placeholder="Profession"
                    formControlName="position"
                  />
                </mat-form-field>
              </div>

              <div>
                <mat-form-field
                  appearance="outline"
                  class="px-0 registerField"
                >
                  <mat-label>Professional Association</mat-label>
                  <input
                    matInput
                    placeholder="Professional Association"
                    formControlName="professionalAssociation"
                  />
                </mat-form-field>
              </div>
            </div>

            <ng-container formGroupName="address">
              <div class="two-fields col-12 px-0">
                <div>
                  <mat-form-field
                    appearance="outline"
                    class="px-0 registerField"
                  >
                    <mat-label>Adddress</mat-label>
                    <input
                      #placesRef="ngx-places"
                      ngx-gp-autocomplete
                      (onAddressChange)="handleAddressChange($event)"
                      required
                      matInput
                      placeholder="Address ( number & street )"
                      formControlName="address"
                    />

                    <!-- <ngx-google-places-autocomplete
                      [FormControl]="address"
                      [types]="['address']"
                      (onAddressChange)="handleAddressChange($event)"
                    >
                    </ngx-google-places-autocomplete> -->
                  </mat-form-field>

                  <p>
                    Please use autocomplete to fill out your address
                    information.
                  </p>
                </div>

                <div>
                  <mat-form-field
                    appearance="outline"
                    class="px-0 registerField"
                  >
                    <mat-label>Postal Code</mat-label>
                    <input
                      matInput
                      placeholder="Postal/Zip Code"
                      formControlName="postalCode"
                    />
                  </mat-form-field>
                </div>
              </div>

              <div class="two-fields col-12 px-0">
                <div>
                  <mat-form-field
                    appearance="outline"
                    class="col-6 px-0"
                  >
                    <mat-label>Country</mat-label>
                    <input
                      matInput
                      placeholder="Country"
                      formControlName="country"
                    />
                  </mat-form-field>
                </div>

                <div>
                  <mat-form-field
                    appearance="outline"
                    class="col-6 px-0"
                  >
                    <mat-label>Province</mat-label>
                    <input
                      matInput
                      placeholder="Province/State"
                      formControlName="province"
                    />
                  </mat-form-field>
                </div>
              </div>
            </ng-container>

            <div>
              <mat-form-field
                appearance="outline"
                class="col-12 px-0 registerField"
              >
                <mat-label>Referred By</mat-label>
                <input
                  matInput
                  placeholder="Referred By"
                  formControlName="referredBy"
                />
              </mat-form-field>
            </div>

            <div>
              <mat-form-field
                appearance="outline"
                class="col-12 px-0 registerField"
              >
                <mat-label>Phone number</mat-label>
                <input
                  matInput
                  type="tel"
                  placeholder="Phone Number"
                  formControlName="phoneNumber"
                />
              </mat-form-field>
            </div>

            <div class="d-flex subdomain">
              <span>https://</span>
              <mat-form-field
                appearance="outline"
                class="col-12 px-0 registerField"
              >
                <mat-label>Subdomain</mat-label>
                <input
                  matInput
                  placeholder="Subdomain"
                  formControlName="subdomain"
                />
              </mat-form-field>
              <span>.ls.works</span>
            </div>
          </div>
        </div>

        <div class="terms">
          <div>
            <div class="termsOfService">
              <div (scroll)="onScroll($event)">
                <app-terms-of-service-box></app-terms-of-service-box>
              </div>

              <p>
                * To activate the I accept the Terms of Service button. You must
                scroll to the end
              </p>
            </div>

            <br />

            <mat-checkbox
              [disabled]="!allowTermsAgree"
              formControlName="acceptedTerms"
            >
              I accept the Terms of Service
            </mat-checkbox>

            <div class="col-12 my-3 px-0 d-flex justify-content-center">
              <div *ngIf="!updatingOrg">
                <app-simple-button
                  [disabled]="!orgFormGroup.valid"
                  class="m-auto submitBtn"
                  text="SAVE"
                  (click)="updateOrganization()"
                ></app-simple-button>
              </div>

              <div *ngIf="updatingOrg">
                <div class="d-flex justify-content-center align-items-center">
                  <fa-icon
                    class="mx-2"
                    [icon]="spinnerIcon"
                    [spin]="true"
                  >
                  </fa-icon>

                  <div>Please wait, we're saving your information.</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</app-main-layout>
