import { Component, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { RestAPIService } from 'src/app/services/rest/rest-api.service';
import { Router } from '@angular/router';
import { GameStundentDTO, QueryData } from 'src/app/shared/components/game/game.interface';
import { GameComponent } from 'src/app/shared/components/game/game.component';
import { FormControl } from '@angular/forms';
import { get, isEmpty, clone, includes, uniqBy } from 'lodash';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faArrowCircleLeft, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { themes } from '../../shared/consts/global-constants';
import { gameImages, gameLevels, excludedDemoExercises, gumball, GameTag } from './constants/demo-constants';
import { RoleService } from 'src/app/services/roles/role.service';
import { HttpParams } from '@angular/common/http';
import { classic } from 'src/app/shared/consts/themeLabels';
import { GlobalConfigurationHelper } from 'src/app/services/utils/global-configuration-helper';
import { Category, Language } from '../configuration-pages/interfaces/global-config.interfaces';
import {
  Theme,
  ThemeLanguage,
} from '../configuration-pages/content-configurations/components/themes/interfaces/themes.interface';
import { defaultLanguage } from '../configuration-pages/content-configurations/components/categories/utils/categories-utils';
import { DemoData } from 'src/app/shared/interfaces/demo.interface';
import { AuthService } from 'src/app/services/auth/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-demo',
  templateUrl: './demo.component.html',
  styleUrls: ['./demo.component.scss'],
})
export class DemoComponent implements OnInit {
  public queryData: QueryData;
  public gameStudentDTO: GameStundentDTO;
  public games = new FormControl();
  public themes = new FormControl();
  public languages = new FormControl();
  public level = new FormControl();
  public loading = false;
  public showGame = false;
  public loadingContent = true;

  public allThemes = [];
  public allLanguages = [];
  public enabledLanguages = [];
  public enabledThemes = [];
  public categories = [];
  public availableCategories = [];

  public gameOptions: Partial<Category>[] = [];

  public gameImages = gameImages;

  public gameLevels = clone(gameLevels);

  public getB2cThemesPath = 'themes/getB2cAllowedThemes';
  public getOrgThemesPath = 'themes/findAllowedThemes';

  public overwriteSessionDetails;
  public windowWidth;

  public demoName: string;

  public readonly back: IconDefinition = faArrowCircleLeft;
  public readonly loadingIcon: IconDefinition = faSpinner;

  @ViewChild(GameComponent, { static: false }) gameComponentRef: GameComponent;
  @ViewChild('iframe') iframe: ElementRef;
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.windowWidth = event.target.innerWidth;
  }

  constructor(
    public _rest: RestAPIService,
    private router: Router,
    public roleService: RoleService,
    public globalConfigHelper: GlobalConfigurationHelper,
    public authService: AuthService,
    public snackBar: MatSnackBar,
  ) {
    // Force page refresh even if is on the same route
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  async ngOnInit() {
    this.windowWidth = window.innerWidth;
    await this.getDemosData();

    const queryParams = get(this.router, 'currentUrlTree.queryParams');

    if (isEmpty(queryParams) === false) {
      const game = queryParams.game;
      const theme = queryParams.theme;
      const lang = queryParams.lang;
      const level = queryParams.level;

      if (this.shouldStartDemo(game, theme, lang, level)) {
        this.startDemo(queryParams.game, queryParams.theme, queryParams.lang, queryParams.level);
      }
    }

    this.loadingContent = false;
  }

  public isAuthenticated(): boolean {
    return !isEmpty(this.authService.user);
  }

  public async getDemosData() {
    try {
      const demoData: DemoData = await this._rest.get('/gameFiles/demos/infos');

      if (!demoData) {
        throw new Error(
          'We could not find the demo data, please reload the page. If the problem persists contact our support',
        );
      }

      this.setCategories(demoData.categories);
      this.setThemes(demoData.themes);
      this.setLanguages(demoData.languages);
      this.getEnabledLanguages();
      this.setFormValues();
    } catch (error) {
      this.router.navigate(['/']);
      this.snackBar.open(error.message, 'Close', {
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
    }
  }

  public setCategories(categories: Category[]) {
    this.categories = categories;

    this.gameOptions = categories
      .filter((c) => !includes(excludedDemoExercises, c.title))
      .sort((a, b) => a.order - b.order);

    this.gameOptions.push(gumball);
  }

  public setThemes(themes: Theme[]) {
    this.enabledThemes = themes;
  }

  public setLanguages(languages: Language[]) {
    this.allLanguages = languages;
  }

  public shouldStartDemo(game: string, theme: string, lang: string, level: string) {
    return !isEmpty(game) && !isEmpty(theme) && !isEmpty(lang) && !isEmpty(level);
  }

  public getAvaliableCategories() {
    return this.categories
      .filter((c: Category) => !includes(excludedDemoExercises, c.title) && c.usedOnDemos)
      .sort((a, b) => a.name.localeCompare(b.name));
  }

  public async getCategories() {
    const categories = await this.globalConfigHelper.getCategories();

    if (!categories) {
      return;
    }

    this.categories = categories;

    this.gameOptions = categories
      .filter((c) => !includes(excludedDemoExercises, c.title))
      .sort((a, b) => a.name.localeCompare(b.name));

    this.gameOptions.push(gumball);
  }

  public shouldDisableStart() {
    return (
      (!this.games.value || !this.themes.value || !this.languages.value || this.level.value === null) &&
      !this.isMouseTraining()
    );
  }

  public isMouseTraining() {
    return this.games.value === GameTag.Gumball;
  }

  public getCategoryDemoLabel(category: Category) {
    return category.title === GameTag.Gumball ? category.title : category.title + 'Demo';
  }

  public async startDemo(
    game: string = this.games.value,
    selectedTheme: string = this.themes.value,
    language: string = this.languages.value,
    selectedLevel: string = this.level.value,
  ) {
    this.loading = true;
    const categoryType = this.gameOptions.find((g) => g.title === this.removeDemoFromLabel(game));
    const session = 0;
    const level = selectedLevel || '0';
    const category = categoryType ? categoryType.id : '';
    const lang = language || 'en_ca';
    const week = 0;
    const theme = selectedTheme || classic;
    const gameDuration = categoryType ? categoryType.gameDuration : 100;

    this.demoName = categoryType?.name;

    this.gameStudentDTO = {
      name: 'Demo Student',
      language: lang,
      level: Number(this.level),
      program: 1,
      progress: session,
    };

    this.queryData = {
      week: `l${week}`,
      level,
      category,
      session,
      lang,
      theme,
      gameDuration,
    };

    this.overwriteSessionDetails = await this.requestDemoSessionDetails(theme, lang, category, level, game);
    this.showGame = true;
    this.loading = false;
  }

  public async endDemo() {
    this.gameComponentRef?.endSession();
    this.showGame = false;
  }

  public async getThemes() {
    const path = this.roleService.isB2C() ? this.getB2cThemesPath : this.getOrgThemesPath;
    this.allThemes = await this._rest.get(path, { msg: 'Could not get themes' });

    if (themes) {
      this.enabledThemes = this.allThemes.filter((t: Theme) => t.isEnabled && t.avaliableForDemo);
    }
  }

  public setAvailableCategories(theme: Theme) {
    const availableCategories = this.categories.filter(
      (c: Category) => !includes(excludedDemoExercises, c.title) && c.usedOnDemos,
    );

    if (theme) {
      const categoryList = theme.categories.map((c) => c.id || c.name);
      const filtedCategories = availableCategories.filter(
        (c) => categoryList.includes(c.id) || categoryList.includes(c.name.toLowerCase()),
      );
      this.availableCategories = filtedCategories.sort((a, b) => a.name.localeCompare(b.name));
    }
  }

  public setAvaliableLanguages() {
    const selectedTheme: Theme = this.enabledThemes.find((t: Theme) => t.variableName === this.themes.value);

    if (selectedTheme) {
      this.enabledLanguages = this.enabledLanguages.filter((l) => this.isLanguageAvaliable(l, selectedTheme));
      this.filterLevels();
    }
  }

  public isLanguageAvaliable(lang: ThemeLanguage, theme: Theme) {
    if (!lang) {
      return false;
    }

    const languages: ThemeLanguage[] = get(theme, 'languages', []);

    const selectedLanguage = languages.find(
      (l) => l.languageId === lang.languageId || l.languageCode === lang.languageCode,
    );

    return selectedLanguage != undefined;
  }

  public setFormValues() {
    const defaultTheme = this.enabledThemes.find((t: Theme) => t.isDefault);

    if (!this.themes.value) {
      // property we use to identify the theme
      const variableName = get(defaultTheme, 'variableName', '') || get(this.enabledThemes[0], 'variableName', '');

      this.themes.setValue(variableName);
    }

    if (!this.languages.value) {
      this.languages.setValue(defaultLanguage);
    }

    if (!this.games.value) {
      this.games.setValue(this.getCategoryDemoLabel(this.categories[0]));
    }

    if (!this.level.value) {
      this.gameLevels = this.enabledLanguages[0].enabledLevels.filter((l) => l.enabled);
      this.level.setValue(0);
    }

    this.setAvailableCategories(defaultTheme);
    this.setAvaliableLanguages();
  }

  public async getLanguages() {
    this.allLanguages = await this._rest.get('languages', { msg: 'Could not get languages' });
  }

  public getEnabledLanguages() {
    const enabledLanguages = [];

    this.enabledThemes.map((theme) => {
      if (theme.languages) {
        for (const language of theme.languages) {
          enabledLanguages.push(language);
        }
      }
    });

    this.enabledLanguages = uniqBy(enabledLanguages, 'languageId');
  }

  public getThemeLabel(theme) {
    const findTheme = themes.find((t) => t.name === theme.label.en_ca);

    return findTheme ? findTheme.label : theme.label.en_ca;
  }

  public getLanguageLabel(lang: ThemeLanguage) {
    let languageLabel = '';

    if (lang.name) {
      languageLabel = lang.name;
    } else {
      const language = this.allLanguages.find((l) => l.languageCode === lang.languageCode);

      languageLabel = language.name;
    }

    return languageLabel;
  }

  public gameGameImage(label: string) {
    const selectedGame = this.gameOptions.find((g) => g.title === this.removeDemoFromLabel(label));

    if (selectedGame) {
      return selectedGame.img;
    }
  }

  public removeDemoFromLabel(title: string) {
    if (title.toLowerCase().includes('demo')) {
      return title.replace(/demo/gi, '');
    }

    return title;
  }

  public filterLangs(theme: Theme) {
    this.enabledLanguages = theme.languages;

    // Check if the language option is already choosen and if is enabled , if its not auto select the first avaliable option and filter the level options
    if (this.languages.value) {
      const language = this.enabledLanguages.find((l) => l.languageCode === this.languages.value);

      if (!language) {
        this.languages.setValue(this.enabledLanguages[0].languageCode);
        this.gameLevels = this.enabledLanguages[0].enabledLevels.filter((l) => l.enabled);
      } else {
        this.gameLevels = language.enabledLevels.filter((l) => l.enabled);
      }

      this.filterLevels();
    }
  }

  public filterLevels() {
    const language = this.enabledLanguages.find((l) => l.languageCode === this.languages.value);

    if (language) {
      this.gameLevels = language.enabledLevels.filter((l) => l.enabled);
      const gameLevel = this.gameLevels.find((l) => l.assessmentLevel === this.level.value);

      if (!gameLevel) {
        this.level.setValue(this.gameLevels[0].assessmentLevel);
      }
    }
  }

  public async requestDemoSessionDetails(theme: string, lang: string, category: string, level: string, name: string) {
    const sessionDetails = await this._rest.get(
      `/demos/demosConfigs/language/${lang}/theme/${theme || classic}/level/${level}/category/${category || name}`,
      {
        params: new HttpParams().set('categoryName', name),
        msg: 'Could not get session',
      },
    );

    return sessionDetails;
  }
}
