<app-main-layout
  [breadcrumbs]="[
    { name: 'Home', url: '/' },
    { name: 'Payment manager', url: '/classwallet dashboard' }
  ]"
>
  <div class="header">
    <div class="d-flex justify-content-around selector">
      <div
        (click)="changeOrders(orderOptions.OPEN)"
        [ngClass]="{
          chosenOption: activeTable === orderOptions.OPEN
        }"
      >
        Open orders
      </div>

      <div
        (click)="changeOrders(orderOptions.COMPLETED)"
        [ngClass]="{
          chosenOption: activeTable === orderOptions.COMPLETED
        }"
      >
        Completed orders
      </div>
    </div>
  </div>

  <div
    *ngIf="pageLoading"
    class="pageLoading"
  >
    <fa-icon
      class="regularIcon"
      [icon]="spinner"
      [spin]="true"
    ></fa-icon>
  </div>

  <div
    *ngIf="!pageLoading && isEmptyDataSource()"
    class="pageLoading warning"
  >
    You dont have {{ activeTable }} orders
  </div>

  <div
    class="emailFilter col-10 px-0 mx-auto mt-5"
    [ngClass]="{ 'd-none': isEmptyDataSource() }"
  >
    <mat-form-field class="w-100">
      <mat-label>Email Filter</mat-label>
      <input
        matInput
        (keyup)="applyFilter($event)"
        placeholder="Ex. youremail@gmail.com"
        #input
      />
    </mat-form-field>
  </div>

  <div
    class="w-100 d-flex justify-content-center"
    [ngClass]="{ 'd-none': isEmptyDataSource() }"
  >
    <div
      *ngIf="activeTable === orderOptions.COMPLETED"
      class="w-100 d-flex justify-content-center filterContainer"
    >
      <div class="wrapper mx-auto">
        <div
          class="filters d-flex justify-content-center mx-2"
          *ngFor="let colum of filterApprovedColumns; let i = index"
        >
          <mat-accordion
            [ngStyle]="{ 'z-index': filterOpenColumns.length - i }"
          >
            <mat-expansion-panel class="defaultExpansionPanel">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <fa-icon
                    [icon]="plus"
                    class="mx-1"
                  >
                  </fa-icon>

                  <span class="mx-1 filterText">
                    {{ colum }}
                  </span>
                </mat-panel-title>
              </mat-expansion-panel-header>

              <div *ngIf="colum === 'Approval date'">
                <app-cw-date-filter
                  [activeList]="activeList"
                  [dateField]="'approvedAt'"
                ></app-cw-date-filter>
              </div>

              <div
                *ngIf="colum === 'Status'"
                class="body"
              >
                <app-cw-list-filter
                  [activeList]="activeList"
                  [listType]="'Status'"
                  [listItems]="['Approved', 'Paid']"
                  [status]="'Approved'"
                >
                </app-cw-list-filter>
              </div>

              <div
                *ngIf="colum === 'Account created'"
                class="body"
              >
                <app-cw-boolean-filter
                  [propertyType]="'accountId'"
                  [propertyValue]="true"
                  [activeList]="activeList"
                >
                </app-cw-boolean-filter>
              </div>

              <div
                *ngIf="colum === 'Token delivered'"
                class="body"
              >
                <app-cw-boolean-filter
                  [propertyType]="'tokenDelivered'"
                  [propertyValue]="true"
                  [activeList]="activeList"
                >
                </app-cw-boolean-filter>
              </div>

              <div
                *ngIf="colum === 'State'"
                class="body"
              >
                <div class="col-12 px-0">
                  <div>Filter by state</div>

                  <div>
                    <mat-form-field class="mx-1">
                      <mat-select [(value)]="stateInput">
                        <mat-option
                          *ngFor="let option of activeList"
                          [value]="option.province"
                          >{{ option.province }}</mat-option
                        >
                      </mat-select>
                    </mat-form-field>
                  </div>

                  <div class="button d-flex justify-content-center">
                    <app-simple-button
                      (click)="activeState = stateInput"
                      [disabled]="activeState"
                      [fullWidth]="true"
                      class="mx-auto"
                      [text]="'Apply'"
                    >
                    </app-simple-button>

                    <app-simple-button
                      (click)="activeState = undefined"
                      [fullWidth]="true"
                      [disabled]="!activeState"
                      class="mx-auto"
                      [text]="'Clear'"
                    >
                    </app-simple-button>
                  </div>
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>
    </div>

    <div
      *ngIf="activeTable === orderOptions.OPEN"
      class="w-100 d-flex justify-content-center filterContainer"
    >
      <div class="wrapper">
        <div
          class="filters d-flex justify-content-center mx-2"
          *ngFor="let colum of filterOpenColumns; let i = index"
        >
          <mat-accordion
            [ngStyle]="{ 'z-index': filterOpenColumns.length - i }"
          >
            <mat-expansion-panel class="defaultExpansionPanel">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <fa-icon
                    [icon]="plus"
                    class="mx-1"
                  >
                  </fa-icon>

                  <span class="mx-1 filterText">
                    {{ colum }}
                  </span>
                </mat-panel-title>
              </mat-expansion-panel-header>

              <div *ngIf="colum === 'Creation date'">
                <app-cw-date-filter
                  [activeList]="activeList"
                  [dateField]="'createdAt'"
                ></app-cw-date-filter>
              </div>

              <div
                *ngIf="colum === 'Status'"
                class="body"
              >
                <app-cw-list-filter
                  [activeList]="activeList"
                  [listType]="'Status'"
                  [listItems]="['Open', 'Canceled']"
                  [status]="'Open'"
                >
                </app-cw-list-filter>
              </div>

              <div
                *ngIf="colum === 'Account created'"
                class="body"
              >
                <app-cw-boolean-filter
                  [propertyType]="'accountId'"
                  [propertyValue]="true"
                  [activeList]="activeList"
                >
                </app-cw-boolean-filter>
              </div>

              <div
                *ngIf="colum === 'State'"
                class="body"
              >
                <div class="col-12 px-0">
                  <div>Filter by state</div>

                  <div>
                    <mat-form-field class="mx-1">
                      <mat-select [(value)]="stateInput">
                        <mat-option
                          *ngFor="let option of activeList"
                          [value]="option.province"
                          >{{ option.province }}</mat-option
                        >
                      </mat-select>
                    </mat-form-field>
                  </div>

                  <div class="button d-flex justify-content-center">
                    <app-simple-button
                      (click)="activeState = stateInput"
                      [disabled]="activeState"
                      [fullWidth]="true"
                      class="mx-auto"
                      [text]="'Apply'"
                    >
                    </app-simple-button>

                    <app-simple-button
                      (click)="activeState = undefined"
                      [fullWidth]="true"
                      [disabled]="!activeState"
                      class="mx-auto"
                      [text]="'Clear'"
                    >
                    </app-simple-button>
                  </div>
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>
    </div>
  </div>

  <div
    class="statementExport col-10 px-0 mx-auto"
    *ngIf="activeTable === orderOptions.COMPLETED"
  >
    <app-simple-button
      (click)="exportStatement()"
      [text]="'Download order statement'"
    >
    </app-simple-button>
  </div>

  <div
    class="col-10 px-0 position-relative mx-auto my-3"
    *ngIf="!pageLoading"
    [ngClass]="{ 'd-none': isEmptyDataSource() }"
  >
    <div
      *ngFor="let list of activeList"
      class="my-5"
      [ngClass]="{
        'd-none': activeState !== undefined && activeState !== list.province
      }"
    >
      <div class="stateHeader p-2">
        {{ list.province }}
      </div>

      <div class="mainContainer p-4 mat-elevation-z8">
        <table
          mat-table
          matSort
          [dataSource]="list.dataSource"
          class="col-9 p-2 text-center mt-4 mx-auto"
        >
          <div class="table">
            <ng-container
              matColumnDef="poNumber"
              class="text-center"
            >
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                class="text-center"
              >
                PO Number
              </th>
              <td
                mat-cell
                *matCellDef="let order"
                class="text-center"
              >
                {{ order.poNumber }}
              </td>
            </ng-container>
            <ng-container
              matColumnDef="orderNumber"
              class="text-center"
            >
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                class="text-center"
              >
                Order Number
              </th>
              <td
                mat-cell
                *matCellDef="let order"
                class="text-center"
              >
                {{ order.orderNumber }}
              </td>
            </ng-container>
            <ng-container
              matColumnDef="email"
              class="text-center"
            >
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                class="text-center"
              >
                Email
              </th>
              <td
                mat-cell
                *matCellDef="let order"
                class="text-center"
              >
                {{ order.email }}
              </td>
            </ng-container>

            <ng-container
              matColumnDef="cost"
              class="text-center"
            >
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                class="text-center"
              >
                Cost
              </th>
              <td
                mat-cell
                *matCellDef="let order"
                class="text-center"
              >
                {{ order.total_price }}
              </td>
            </ng-container>

            <ng-container
              matColumnDef="createdAt"
              class="text-center"
              *ngIf="activeTable === 'open'"
            >
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                class="text-center"
              >
                Created At
              </th>
              <td
                mat-cell
                *matCellDef="let order"
                class="text-center"
              >
                {{ order.createdAt | date: 'dd/MM/yyyy' }}
              </td>
            </ng-container>

            <ng-container
              matColumnDef="approvedAt"
              class="text-center"
              *ngIf="activeTable === 'completed'"
            >
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                class="text-center"
              >
                Approved At
              </th>
              <td
                mat-cell
                *matCellDef="let order"
                class="text-center"
              >
                {{ order.approvedAt | date: 'dd/MM/yyyy' }}
              </td>
            </ng-container>

            <ng-container
              matColumnDef="status"
              class="text-center"
            >
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                class="text-center"
              >
                Status
              </th>
              <td
                mat-cell
                *matCellDef="let order"
                class="text-center"
              >
                <fa-icon
                  class="mr-2 status {{ order.financial_status }}"
                  [icon]="circle"
                ></fa-icon>
                {{ getStatus(order.orderStatus) }}
              </td>
            </ng-container>

            <ng-container
              matColumnDef="account created"
              class="text-center"
            >
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                class="text-center"
              >
                Account Created
              </th>
              <td
                mat-cell
                *matCellDef="let order"
                class="text-center"
              >
                {{ order.accountId ? 'Yes' : 'No' }}
              </td>
            </ng-container>

            <ng-container
              matColumnDef="token delivered"
              class="text-center"
            >
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                class="text-center"
              >
                Token Delivered
              </th>
              <td
                mat-cell
                *matCellDef="let order"
                class="text-center"
              >
                {{ order.tokenDelivered ? 'Yes' : 'No' }}
              </td>
            </ng-container>

            <ng-container
              matColumnDef="actions"
              class="text-center"
            >
              <th
                mat-header-cell
                *matHeaderCellDef
                class="text-center"
              >
                Actions
              </th>
              <td
                mat-cell
                *matCellDef="let order"
                class="actionIcons"
                class="text-center"
              >
                <a
                  (click)="forceTokenDelivery(order)"
                  [matTooltip]="
                    !isApproved(order.orderStatus)
                      ? 'This order isnt approved'
                      : order.tokenDelivered
                      ? 'This order is completed'
                      : 'Deliver the order tokens'
                  "
                  matTooltipPosition="above"
                >
                  <fa-icon
                    [ngClass]="{
                      disabledIcon: !allowForceFulfillment(order)
                    }"
                    class="mx-2 regularIcon"
                    [icon]="fulfill"
                  >
                  </fa-icon>
                </a>
              </td>
            </ng-container>
          </div>

          <tr
            mat-header-row
            *matHeaderRowDef="activeColumn"
          ></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: activeColumn"
          ></tr>
          <tr
            class="mat-row"
            *matNoDataRow
          >
            <td
              class="mat-cell footerRow"
              colspan="4"
            >
              No data matching the filter
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</app-main-layout>
