export const DEFAULT_THEME = 'classic';
export const READING_COMP = 'readingcomp';
export const VIDEO = 'video';

// Unity Initialization
export const SESSION_CONFIG_JSON_URL = '/values.json';
export const UNITY_LOADER_URL = '/Build/readls-unity-2020.loader.js';
export const UNITY_DATA_URL = '/Build/readls-unity-2020.data';
export const UNITY_FRAMEWORK_URL = '/Build/readls-unity-2020.framework.js';
export const UNITY_CODE_URL = '/Build/readls-unity-2020.wasm';
export const UNITY_ASSET_BUNDLE_PATH = '/StreamingAssets';

export const UNICODE_OVERRIDE = [
  ['“', '\\"'],
  ['”', '\\"'],
  ['…', '...'],
  ['–', '-'],
];

export enum UnityMessages {
  APP_READY = 'AppReady',
  SAVE_PROGRESS = 'SaveProgress',
  SAVE_PHONEMEFAIR_PROGRESS = 'SavePhonemeFairProgress',
  APP_NOT_READY = 'AppNotReady',
  SESSION_COMPLETE = 'SessionComplete',
  SKIPED_EXERCISE = 'SkipedExercise',
}

export interface SessionSkipData {
  studentInfo: UnityStudentInfo;
  gameType: string;
}

export interface UnityStudentInfo {
  name: string;
  language: string;
  level: string;
  session: string;
}

// -- UNITY CONFIG -- //
export const PHONEMEFAIR_URL = 'https://lsworks-files.storage.googleapis.com/phoneme-fair-dev';
export const PHONEMEFAIR_LOADER = '/Build/WebGL.loader.js';
export const PHONEMEFAIR_FRAMEWORK = '/Build/WebGL.framework.js';
export const PHONEMEFAIR_DATA = '/Build/WebGL.data';
export const PHONEMEFAIR_WASM = '/Build/WebGL.wasm';

export enum GameState {
  LOAD_GAME = 'load',
  PLAY_FROM_BEGGINING = 'playFromBeggining',
}
