import { Component, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'users-add-new-button',
  templateUrl: './add-new-button.component.html',
  styleUrls: ['./add-new-button.component.scss'],
})
export class AddNewButtonComponent {
  public faPlus = faPlus;

  @Output() onAddNewUser = new EventEmitter();

  constructor(private _router: Router) {}

  public goto(path: string, org: any) {
    if (org) {
      this._router.navigate([path], { queryParams: { id: org.id, name: org.organization.name } });
    } else {
      this._router.navigate([path]);
    }
  }

  public handleClick() {
    this._router.navigate(['/users/manage/0']);
  }
}
