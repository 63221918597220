import { Router } from '@angular/router';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { StripeObservableService } from '../../stripe-observable.service';
import { RestAPIService } from 'src/app/services/rest/rest-api.service';

import {
  StripeDataModal,
  StripeDecimalMultiplier,
  StripeHttpMethod,
  StripePriceType,
  StripeProduct,
  StripeProductModal,
} from 'src/app/shared/interfaces/Stripe.interfaces';

@Component({
  selector: 'app-product-stripe-modal',
  templateUrl: './product-stripe-modal.component.html',
  styleUrls: ['../modal-stripe.component.scss'],
})
export class ProductStripeModalComponent {
  products: StripeProduct[];

  productId: string;

  //Modal config
  type: string;
  service: string;
  typeHttp: string;
  routeApi: string;

  //Form product
  productName: string;
  productDescription: string;
  productCurrency: string;
  productInterval: string;
  productAmount: string;
  productDefaultPrice: boolean;
  productPriceCurrency: string;
  productPriceUnitAmount: number;
  productPriceType: string;
  productPriceInterval: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: StripeDataModal,
    public dialogRef: MatDialogRef<ProductStripeModalComponent>,
    public route: Router,
    private _stripeService: StripeObservableService,
    private _rest: RestAPIService,
  ) {
    this.products = [];
    this.type = data.type;
    this.service = data.service;
    this.routeApi = data.routeApi;
    this.typeHttp = data.typeHttp;

    if (data.stripeDataModal) {
      this.initData(data.stripeDataModal.data);
    }
  }

  public close() {
    this.dialogRef.close(true);
  }

  private initData(data: StripeProductModal) {
    this.productId = data.id;
    this.productName = data.productName;
    this.productDescription = data.productDescription;
    this.productPriceCurrency = data.productPriceCurrency;
    this.productPriceUnitAmount = data.productPriceUnitAmount / StripeDecimalMultiplier.Hundred;
    if (data.productPriceInterval) {
      this.productPriceType = StripePriceType.Recurring;
      this.productPriceInterval = data.productPriceInterval;
    }
  }

  public async save() {
    if (this.typeHttp === StripeHttpMethod.POST) {
      await this.create();
      this.close();
    } else if (this.typeHttp === StripeHttpMethod.PUT) {
      await this.update();
      this.close();
    }
  }

  private async create() {
    try {
      const product = {
        name: this.productName,
        description: this.productDescription,
        default_price_data: {
          currency: this.productPriceCurrency,
          unit_amount: this.productPriceUnitAmount * StripeDecimalMultiplier.Hundred,
          recurring: {
            interval: this.productPriceInterval,
          },
        },
      };
      const createProduct = await this._rest.post(this.routeApi, product);
      this._stripeService.changeProduct(createProduct);
    } catch (error) {
      throw new Error(error);
    }
  }

  private async update() {
    try {
      const data = {
        name: this.productName,
        description: this.productDescription,
      };
      if (this.productDefaultPrice) {
        data['default_price'] = null;
      }
      const updateProduct = await this._rest.put(`${this.routeApi}/id/${this.productId}`, data);
      if (updateProduct) {
        this._stripeService.changeProduct(updateProduct);
      }
    } catch (error) {
      throw new Error(error);
    }
  }
}
