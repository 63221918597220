<ng-container *ngIf="template === 'table'">
  <users-table-header heading="Organizations"></users-table-header>

  <users-table-search-input
    placeholder="Search organizations..."
    (userKeyUp)="applyFilter($event)"
  ></users-table-search-input>

  <div class="table">
    <mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
        <mat-cell *matCellDef="let org">
          {{ org.name }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
        <mat-cell *matCellDef="let org">
          <users-table-status-item
            [status]="org.status"
          ></users-table-status-item>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="email">
        <mat-header-cell *matHeaderCellDef> Email </mat-header-cell>
        <mat-cell *matCellDef="let org">
          {{ org.email }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="webpage">
        <mat-header-cell *matHeaderCellDef> Webpage </mat-header-cell>
        <mat-cell *matCellDef="let org">
          <users-table-website-item [website]="org.webpage">
          </users-table-website-item>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="credits">
        <mat-header-cell *matHeaderCellDef> Credits </mat-header-cell>
        <mat-cell *matCellDef="let org">
          <users-table-credits-badge-item
            *ngIf="org.credits !== undefined"
            [credits]="org.credits"
          ></users-table-credits-badge-item>

          <fa-icon
            *ngIf="org.credits === undefined"
            [icon]="spinner"
            [spin]="true"
          >
          </fa-icon>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef> Actions </mat-header-cell>
        <mat-cell *matCellDef="let org">
          <div class="icons">
            <users-table-action-icon
              [icon]="'plus'"
              [tooltip]="'Add credits'"
              (click)="addCredits(org)"
            ></users-table-action-icon>
            <users-table-action-icon
              [icon]="'minus'"
              [tooltip]="'Remove credits'"
              (click)="retrieveCredits(org)"
            ></users-table-action-icon>
            <users-table-action-icon
              [icon]="'edit'"
              [tooltip]="'Edit organization'"
              (click)="editOrganization(org)"
            ></users-table-action-icon>
            <users-table-action-icon
              [icon]="'redo'"
              [tooltip]="getEmailVerifiedTooltip(org.emailVerified)"
              (click)="resendVerificationEmail(org)"
              [disabled]="org.emailVerified"
            ></users-table-action-icon>
            <users-table-action-icon
              [icon]="'shop'"
              [tooltip]="'Adjust custom price'"
              (click)="adjustCustomPrice(org)"
            ></users-table-action-icon>
            <users-table-action-icon
              [icon]="'trash'"
              [tooltip]="'Delete client'"
              (click)="deleteOrg(org)"
            ></users-table-action-icon>
          </div>
        </mat-cell>
      </ng-container>
      <mat-header-row
        *matHeaderRowDef="
          ['name', 'status', 'email', 'webpage', 'credits', 'actions'];
          sticky: true
        "
      ></mat-header-row>
      <mat-row
        *matRowDef="
          let row;
          columns: ['name', 'status', 'email', 'webpage', 'credits', 'actions']
        "
      ></mat-row>
    </mat-table>
    <ng-container *ngIf="isDataSourceEmpty()">
      <users-empty-state
        class="no-users"
        text="There are currently no organizations. You can add an organization by clicking on the Add New button in the top right corner."
        template="noData"
      ></users-empty-state>
    </ng-container>
  </div>
</ng-container>

<ng-container *ngIf="template === 'loading'"
  ><app-loading-indicator></app-loading-indicator
></ng-container>
