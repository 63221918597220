<div
  class="mainContainer px-3 py-4"
  *ngIf="!loadingInfo"
>
  <div class="my-auto">
    <div class="assessmentHeader header text-center">Assessment Level</div>

    <table
      mat-table
      class="col-12 px-0"
      multiTemplateDataRows
      [dataSource]="programDataSource"
    >
      <ng-container matColumnDef="program">
        <th
          class="text-center"
          mat-header-cell
          *matHeaderCellDef
        >
          Program
        </th>
        <td
          class="text-center"
          mat-cell
          *matCellDef="let program"
        >
          {{ data.program.name }}
        </td>
      </ng-container>

      <ng-container matColumnDef="level">
        <th
          class="text-center"
          mat-header-cell
          *matHeaderCellDef
        >
          Level Override
          <fa-icon
            [icon]="helpIcon"
            matTooltip="Override deletes the first placement assessment, and you will choose the level for your student."
            matTooltipPosition="above"
          >
          </fa-icon>
        </th>
        <td
          class="text-center"
          mat-cell
          *matCellDef="let program"
        >
          <select
            class="levelSelect mx-2"
            [(ngModel)]="studentAssessmentLevel"
          >
            <option
              *ngFor="let studentLevel of data.levels"
              value="{{ studentLevel.assessmentLevel }}"
            >
              {{ studentLevel.level }}
            </option>
          </select>
          <button
            mat-raised-button
            (click)="saveAssessmentLevel()"
          >
            Save
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="resetLevels">
        <th
          class="text-center"
          mat-header-cell
          *matHeaderCellDef
        >
          Reset category levels
          <fa-icon
            [icon]="helpIcon"
            matTooltip="This will override all category levels to the actual assessment level."
            matTooltipPosition="above"
          >
          </fa-icon>
        </th>
        <td
          class="text-center"
          mat-cell
          *matCellDef="let program"
        >
          <button
            mat-raised-button
            (click)="resetCategoryLevels()"
          >
            Reset
          </button>
        </td>
      </ng-container>

      <tr
        mat-header-row
        *matHeaderRowDef="overrideColums"
      ></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: overrideColums"
      ></tr>
    </table>
  </div>

  <div class="mt-4">
    <div class="assessmentHeader header text-center">Category Levels</div>

    <table
      mat-table
      class="col-12 px-0"
      multiTemplateDataRows
      [dataSource]="categoriesDatasource"
    >
      <ng-container matColumnDef="category">
        <th
          class="text-center"
          mat-header-cell
          *matHeaderCellDef
        >
          Category
        </th>
        <td
          class="text-center"
          mat-cell
          *matCellDef="let category"
        >
          {{ category.name }}
        </td>
      </ng-container>

      <ng-container matColumnDef="level">
        <th
          class="text-center"
          mat-header-cell
          *matHeaderCellDef
        >
          Level Override
        </th>
        <td
          class="text-center"
          mat-cell
          *matCellDef="let category"
        >
          <select
            class="levelSelect mx-2"
            [(ngModel)]="categoryLevels[category.name].assessmentLevel"
          >
            <option
              *ngFor="let studentLevel of data.levels"
              value="{{ studentLevel.assessmentLevel }}"
            >
              {{ studentLevel.level }}
            </option>
          </select>
          <button
            mat-raised-button
            (click)="saveCategoryLevel(category)"
          >
            Save
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th
          class="text-center"
          mat-header-cell
          *matHeaderCellDef
        >
          Enable bonus pack
        </th>
        <td
          class="text-center"
          mat-cell
          *matCellDef="let category"
        >
          <mat-checkbox
            *ngIf="isBonusPackAvaliable(category)"
            [checked]="isBonusPackActivated(category)"
            (click)="enableBonusPack(category)"
          >
            Enable bonus pack
          </mat-checkbox>

          <span *ngIf="!isBonusPackAvaliable(category)">
            This category doesn’t have a bonus pack available
          </span>
        </td>
      </ng-container>

      <tr
        mat-header-row
        *matHeaderRowDef="categoryColums"
      ></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: categoryColums"
      ></tr>
    </table>

    <div class="d-flex justify-content-center mt-4 mb-2">
      <app-simple-button
        [text]="'Close'"
        (click)="close()"
      >
      </app-simple-button>
    </div>
  </div>
</div>
