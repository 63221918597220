import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IconDefinition, faCircleXmark } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-tutorial-modal',
  templateUrl: './tutorial-modal.component.html',
  styleUrls: ['./tutorial-modal.component.scss'],
})
export class TutorialModalComponent implements OnInit {
  public readonly close: IconDefinition = faCircleXmark;
  @ViewChild('videoFrame', { static: true }) myIframe: ElementRef;

  constructor(@Inject(MAT_DIALOG_DATA) public data: string, public dialogRef: MatDialogRef<TutorialModalComponent>) {}

  ngOnInit(): void {}

  public closeModal() {
    this.dialogRef.close();
  }
}
