<app-public-layout>
  <div class="container">
    <h1>Privacy Policy</h1>

    <p>
      Our Privacy Policy was posted on 1st January 2018. It governs the privacy
      terms of our Website, located at
      <a
        href="//ls.works"
        target="_blank"
        >ls.works</a
      ><br /><br />
      Your information is important to Learning Solutions Works. We respect your
      privacy and endeavor to protect your privacy. This Privacy Policy
      (“Policy”) provides information about the personal information that
      Learning Solutions Works collects in which we share and use, and what
      choices are available to you before you decide to provide with us your
      information. WE ENCOURAGE YOU TO CAREFULLY AND FULLY READ THE POLICY
      BEFORE YOU USE OUR WEBSITE. We further welcome your questions and are glad
      to answer your concerns and suggestions.<br /><br />
      Collection of Personal Information<br />
      At ls.works you can make requests, purchase subscriptions and register to
      receive materials/information. In order to realize some of the
      functionalities, we may collect and retain your personal information in
      various ways. The types of personal information collected via these pages
      may include your name, name of your company, address, telephone number,
      fax number, e-mail address and professional information.<br /><br />
      Personal Information Collection<br />
      Personal information is collected for necessary business purposes and also
      to better understand and serve you as a customer or a user of our
      websites. We promise not to, share, transfer and use personal information
      without adequate notice or consent. We stand by this promise. Personal
      information will be collected in various occasions when applicable. For
      example, when you choose to register with ls.works as a registered user,
      subscribe to newsletters, marketing and support materials, participate in
      rewards or promotion program, survey, etc. If you merely browse ls.works
      you will be recorded as a hit only and ls.works will not collect any
      personal information from you unless otherwise stated.<br /><br />
      Information We Collect<br />
      Generally, you control the amount and type of information you provide to
      us when using our Website. As a Visitor, you can browse our website to
      find out more about who we are. You are not required to provide us with
      any Personally Identifiable Information as a Visitor.<br /><br />
      Computer Information Collected<br />
      When you use our Website, we automatically collect certain computer
      information through the interaction of your mobile phone or web browser
      with our Website. Such information is typically considered Non-Personal
      Information. We also collect the following: Cookies Our Website uses
      "Cookies" to identify the areas of our Website that you have visited. A
      Cookie is a small piece of data stored on your computer or mobile device
      by your web browser. We use Cookies to personalize the Content that you
      see on our Website. Most web browsers can be set to disable the use of
      Cookies. However, if you disable Cookies, you may not be able to access
      functionality on our Website correctly or at all. We never place
      Personally Identifiable Information in Cookies.<br /><br />
      Third Party Tracking Tools<br />
      We also use third party tracking tools to improve the performance and
      features of our Website. These third-party tracking tools are designed to
      collect only Non-Personal Information about your use of our Website.
      However, you understand that such tools are created and managed by parties
      outside our control. As such, we are not responsible for what information
      is actually captured by such third parties or how such third parties use
      and protect that information. We use Remarketing with Google Analytics to
      advertise on third party sites to you after you visited our Site. We and
      our third party vendors, like Google, use first-party cookies (such as the
      Google Analytics cookie) and third-party cookies (such as the DoubleClick
      cookie) to inform, optimize and serve ads based on your past visits to our
      Site. You can opt-out of Google Analytics for Display Advertising and
      customize the Google Display Network ads by visiting the Google Ads
      Settings page. Google also recommends installing the Google Analytics
      Opt-out Browser Add-on your browser. Google Analytics Opt-out Browser
      Add-on provides visitors with the ability to prevent their data from being
      collected and used by Google Analytics.<br /><br />
      Automatic Information<br />
      We automatically receive information from your web browser or mobile
      device. This information includes the name of the website from which you
      entered our Website, if any, as well as the name of the website to which
      you're headed when you leave our website. This information also includes
      the IP address of your computer/proxy server that you use to access the
      Internet, your Internet Website provider name, web browser type, type of
      mobile device, and computer operating system. We use all of this
      information to analyze trends among our Users to help improve our
      Website.<br /><br />
      How We Use Your Information<br />
      We use the information we receive from you as follows: Customizing Our
      Website We may use the Personally Identifiable information you provide to
      us along with any computer information we receive to customize our
      Website.<br /><br />
      Sharing Information with Affiliates and Other Third Parties<br />
      We do not sell, rent, or otherwise provide your Personally Identifiable
      Information to third parties for marketing purposes. We may provide your
      Personally Identifiable Information to affiliates that provide services to
      us with regards to our Website (i.e. payment processors, Website hosting
      companies, etc.); such affiliates will only receive information necessary
      to provide the respective services and will be bound by confidentiality
      agreements limiting the use of such information.<br /><br />
      Data Aggregation<br />
      We retain the right to collect and use any Non-Personal Information
      collected from your use of our Website and aggregate such data for
      internal analytics that improve our Website and Service as well as for use
      or resale to others. At no time is your Personally Identifiable
      Information included in such data aggregations.<br /><br />
      Legally Required Releases of Information<br />
      We may be legally required to disclose your Personally Identifiable
      Information, if such disclosure is (a) required by subpoena, law, or
      another legal process; (b) necessary to assist law enforcement officials
      or government enforcement agencies; (c) necessary to investigate
      violations of or otherwise enforce our Legal Terms; (d) necessary to
      protect us from legal action or claims from third parties including you
      and/or other Members; and/or (e) necessary to protect the legal rights,
      personal/real property, or personal safety of TermsFeed, our Users,
      employees, and affiliates.<br /><br />
      Do Not Track ("DNT")<br />
      We do not support Do Not Track ("DNT"). Do Not Track is a preference you
      can set in your web browser to inform websites that you do not want to be
      tracked. You can enable or disable Do Not Track by visiting the
      Preferences or Settings page of your web browser.<br /><br />
      Protecting Your Child's Privacy<br />
      Our Website is not designed for use by anyone under the age of 13
      ("Child"), though we realize we may have a Child attempt to make purchases
      through our Website. We do not verify the age of our Users nor do we have
      any liability for verifying a User's age. If you are a Child, please seek
      the permission of a parent or guardian before using our Website. If you
      are a parent or guardian and believe your Child is using our Website,
      please contact us to remove your Child's account; we reserve the right to
      ask you for verification of your relationship to the Child before we honor
      such a request. If we discover that a Child has created an account on our
      Website, we will immediately delete the account as soon as we discover it,
      we will not use the information for any purpose, and we will not disclose
      the information to third parties. However, as a parent of such a Child,
      you understand that you are legally liable for any transactions created by
      the Child.<br /><br />
      Links to Other Websites<br />
      Our Website may contain links to other websites that are not under our
      direct control. These websites may have their own policies regarding
      privacy. We have no control of or responsibility for linked websites and
      provide these links solely for the convenience and information of our
      visitors. You access such linked Websites at your own risk. These websites
      are not subject to this Privacy Policy. You should check the privacy
      policies, if any, of those individual websites to see how the operators of
      those third-party websites will utilize your personal information. In
      addition, these websites may contain a link to Websites of our affiliates.
      The websites of our affiliates are not subject to this Privacy Policy, and
      you should check their individual privacy policies to see how the
      operators of such websites will utilize your personal information.<br /><br />
      Our Email Policy<br />
      Our affiliates and we fully comply with national laws regarding SPAM. You
      can always opt out of receipt of further email correspondence from us
      and/or our affiliates. We agree that we will not sell, rent, or trade your
      email address to any unaffiliated third-party without your permission.<br /><br />
      Privacy Policy Updates<br />
      We reserve the right to modify this Privacy Policy at any time. You should
      review this Privacy Policy frequently. If we make material changes to this
      policy, we may notify you on our Website, by a blog post, by email, or by
      any method we determine. The method we chose is at our sole discretion. We
      will also change the "Last Updated" date at the beginning of this Privacy
      Policy. Any changes we make to our Privacy Policy are effective as of this
      Last Updated date and replace any prior Privacy Policies.<br /><br />
      English Version<br />
      The Policy is written in English. If this Policy appears in another
      language, this means this document is a translation of the English
      version. In case of discrepancies between the English version and any
      version in another language, the English version shall apply and
      prevail.<br /><br />
      Questions About Our Privacy Practices or This Privacy Policy<br />
      If you have any questions about our Privacy Practices or this Policy,
      please contact us by email at support&#64;lsworks.org.
    </p>
  </div>
</app-public-layout>
