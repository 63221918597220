import { RoleService } from 'src/app/services/roles/role.service';
import { DEFAULT_SIDE_MENU_PREF, MOBILE_BREAKPOINT, ResponsiveView, SideMenuPreferences } from './side-menu.models';
import { get } from 'lodash';
import { AuthService } from 'src/app/services/auth/auth.service';

export const STORAGE_KEY: string = 'side-menu-pref';

export function getSideMenuPreferences(): SideMenuPreferences {
  const pref = JSON.parse(window.localStorage.getItem(STORAGE_KEY)) as SideMenuPreferences;

  if (isValid(pref)) {
    return pref;
  } else {
    window.localStorage.setItem(STORAGE_KEY, JSON.stringify(DEFAULT_SIDE_MENU_PREF));
    return DEFAULT_SIDE_MENU_PREF;
  }
}

export function setResponsiveView(width: number): ResponsiveView {
  if (width <= MOBILE_BREAKPOINT) {
    return 'mobile';
  } else {
    return 'desktop';
  }
}

function isValid(obj: SideMenuPreferences): boolean {
  // null guard
  if (obj == null) {
    return false;
  }

  // loop through obj properties and validate that none of the keys in SideMenuPreferences are missing
  let t: keyof SideMenuPreferences;
  for (t in obj) {
    if (obj[t] === undefined || obj[t] === null) {
      return false; // if any missing properties, obj is invalid
    }
  }

  // otherwise, object is valid
  return true;
}

export function shouldShowSideMenu(auth: AuthService, roles: RoleService) {
  const firstTokenPurhased = get(auth.user, 'firstTokenPurchased', false);
  const pendingRegistration = get(auth.user, 'pendingRegistration', false);

  if (!firstTokenPurhased && !roles.isOrgClient()) {
    // user has not purchased a token and is not a regular client, don't show the menu
    return false;
  } else if (pendingRegistration) {
    // user has not completed registration, don't show the menu
    return false;
  }
  // otherwise, show the menu
  return true;
}
