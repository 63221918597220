<div class="mainContent">
  <div class="col-12 mx-0 px-0 mb-2 justify-content-center">
    <div class="header col-12 text-center">
      Select the allowed payment types
    </div>

    <table
      mat-table
      [dataSource]="dataSource"
      class="col-12 px-0"
      multiTemplateDataRows
    >
      <ng-container matColumnDef="name">
        <th
          mat-header-cell
          *matHeaderCellDef
        >
          Name
        </th>
        <td
          mat-cell
          *matCellDef="let paymentType"
        >
          {{ paymentTypes[paymentType] }}
        </td>
      </ng-container>

      <ng-container matColumnDef="enabled">
        <th
          class="text-center"
          mat-header-cell
          *matHeaderCellDef
        >
          Enabled
        </th>
        <td
          mat-cell
          *matCellDef="let paymentType"
          class="text-center"
        >
          <mat-checkbox
            *ngIf="!checkIfPaymentIsEnabled(paymentType)"
            (click)="changeEnabledStatus(paymentType)"
            [(ngModel)]="unchecked"
          >
          </mat-checkbox>

          <mat-checkbox
            *ngIf="
              isDefaultPaymentType(paymentType) &&
              checkIfPaymentIsEnabled(paymentType)
            "
            matTooltipPosition="above"
            matTooltip="The default payment type cant be disabled"
            disabled
            [(ngModel)]="checked"
          >
          </mat-checkbox>

          <mat-checkbox
            *ngIf="
              checkIfPaymentIsEnabled(paymentType) &&
              !isDefaultPaymentType(paymentType)
            "
            (click)="changeEnabledStatus(paymentType)"
            [(ngModel)]="checked"
          >
          </mat-checkbox>
        </td>
      </ng-container>

      <tr
        mat-header-row
        *matHeaderRowDef="displayedColumns"
      ></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        class="example-element-row"
      ></tr>
    </table>
    <div class="text-center confirmBtn mt-3">
      <app-simple-button
        class="mx-2"
        text="CONFIRM"
        [smaller]="true"
        (click)="confirm()"
      ></app-simple-button>

      <app-simple-button
        class="mx-2"
        text="CANCEL"
        [smaller]="true"
        (click)="cancel()"
      ></app-simple-button>
    </div>
  </div>
</div>
