<div class="main">
  <section class="container">
    <div class="title">
      <h2 *ngIf="typeHttp === 'POST'">Product Registration</h2>
      <h2 *ngIf="typeHttp === 'PUT'">Product Update</h2>
      <h2 *ngIf="type === 'view' && typeHttp === 'GET'">
        Product Visualization
      </h2>
    </div>

    <form class="form">
      <div class="input-block">
        <label for="productName">Name</label>
        <input
          [disabled]="type === 'view'"
          type="text"
          [(ngModel)]="productName"
          name="productName"
          placeholder="Insert the product name"
        />
      </div>
      <div class="input-block">
        <label for="productDescription">Description</label>
        <textarea
          [disabled]="type === 'view'"
          [(ngModel)]="productDescription"
          name="productDescription"
          placeholder="Insert the product description"
        ></textarea>
      </div>
      <div class="input-group">
        <div class="input-block">
          <label for="productPriceCurrency">Currency *</label>
          <select
            [disabled]="type === 'view' || typeHttp === 'PUT'"
            [(ngModel)]="productPriceCurrency"
            name="productPriceCurrency"
          >
            <option
              value=""
              disabled
              selected
            >
              Select the currency
            </option>
            <option value="cad">CAD</option>
            <option value="usd">USD</option>
          </select>
        </div>
        <div class="input-block">
          <label for="productPriceUnitAmount">Price *</label>
          <input
            [disabled]="type === 'view' || typeHttp === 'PUT'"
            type="number"
            [(ngModel)]="productPriceUnitAmount"
            name="productPriceUnitAmount"
            placeholder="Insert the price"
          />
        </div>
      </div>
      <div class="input-group">
        <div class="input-block">
          <label for="productPriceType">Type *</label>
          <select
            [disabled]="type === 'view' || typeHttp === 'PUT'"
            [(ngModel)]="productPriceType"
            name="productPriceType"
          >
            <option
              value=""
              disabled
              selected
            >
              Select the type
            </option>
            <option value="recurring">Recurring</option>
            <option value="one_time">One Time</option>
          </select>
        </div>
        <div class="input-block">
          <div *ngIf="productPriceType === 'recurring'">
            <label for="productPriceInterval">Interval *</label>
            <select
              [disabled]="type === 'view' || typeHttp === 'PUT'"
              [(ngModel)]="productPriceInterval"
              name="productPriceInterval"
            >
              <option
                value=""
                disabled
                selected
              >
                Select the interval
              </option>
              <option value="day">Day</option>
              <option value="week">Week</option>
              <option value="month">Month</option>
              <option value="year">Year</option>
            </select>
          </div>
        </div>
      </div>
      <div
        *ngIf="typeHttp === 'PUT'"
        class="input-block-select"
      >
        <label for="productDefaultPrice">Do you can delete price?</label>
        <select
          [disabled]="type === 'view' || typeHttp === 'POST'"
          [(ngModel)]="productDefaultPrice"
          name="productDefaultPrice"
        >
          <option
            value=""
            disabled
            selected
          >
            Select the answer
          </option>
          <option value="true">Yes</option>
          <option value="false">No</option>
        </select>
      </div>

      <div class="button-group">
        <button
          class="btn-secondary"
          (click)="close()"
        >
          Close
        </button>
        <button
          class="btn-primary"
          *ngIf="type !== 'view'"
          (click)="save()"
        >
          Save
        </button>
      </div>
    </form>
  </section>
</div>
