import moment from 'moment';
import { Router } from '@angular/router';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { StripeObservableService } from '../../stripe-observable.service';
import { RestAPIService } from 'src/app/services/rest/rest-api.service';
import {
  CouponDurationStripe,
  StripeCouponModal,
  StripeCouponType,
  StripeDataModal,
  StripeDecimalMultiplier,
  StripeHttpMethod,
  StripeCoupon,
  StripeCurrency,
} from 'src/app/shared/interfaces/Stripe.interfaces';

@Component({
  selector: 'app-coupon-stripe-modal',
  templateUrl: './coupon-stripe-modal.component.html',
  styleUrls: ['../modal-stripe.component.scss'],
})
export class CouponStripeModalComponent {
  coupons: StripeCoupon[];
  couponId: string;

  // Modal config
  type: string;
  service: string;
  typeHttp: string;
  routeApi: string;

  //Form coupon
  couponAmountOff?: number;
  couponCreated: number;
  couponCurrency?: string;
  couponDuration: CouponDurationStripe;
  couponDurationInMonths?: number;
  couponMaxRedemptions?: number;
  couponMetadata: {
    organizationId: string;
  };
  couponName?: string;
  couponPercentageOff?: number;
  couponRedeemBy?: number | string;
  couponTimesRedeemed: number;
  couponValid: boolean;
  couponType: StripeCouponType;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: StripeDataModal,
    public dialogRef: MatDialogRef<CouponStripeModalComponent>,
    public route: Router,
    private _stripeService: StripeObservableService,
    private _rest: RestAPIService,
  ) {
    this.type = data.type;
    this.service = data.service;
    this.routeApi = data.routeApi;
    this.typeHttp = data.typeHttp;

    if (data.stripeDataModal) {
      this.initData(data.stripeDataModal.data);
    }
  }

  private async initData(data: StripeCouponModal) {
    this.couponId = data.id;
    this.couponDuration = data.couponDuration;
    this.couponDurationInMonths = data.couponDurationInMonths;
    this.couponMaxRedemptions = data.couponMaxRedemptions;
    this.couponMetadata = data.couponMetadata;
    this.couponName = data.couponName;
    this.couponRedeemBy = this.handleTimestampToDate(data.couponRedeemBy);
    this.couponTimesRedeemed = data.couponTimesRedeemed;
    this.couponValid = data.couponValid;
    this.couponType = data.couponAmountOff > 0 ? StripeCouponType.AmountOff : StripeCouponType.PercentOff;
    if (this.couponType === StripeCouponType.AmountOff) {
      this.couponAmountOff = data.couponAmountOff / StripeDecimalMultiplier.Hundred;
      this.couponCurrency = data.couponCurrency ?? StripeCurrency.CAD;
    } else {
      this.couponPercentageOff = data.couponPercentageOff;
      this.couponCurrency = data.couponCurrency ?? StripeCurrency.CAD;
    }
  }

  public close() {
    this.dialogRef.close(true);
  }

  public handleTimestampToDate(timestamp: number) {
    const data = moment.unix(timestamp);
    const formattedDate = data.format('YYYY-MM-DD');
    return formattedDate;
  }

  public async save() {
    if (this.typeHttp === StripeHttpMethod.POST) {
      await this.create();
      this.close();
    } else if (this.typeHttp === StripeHttpMethod.PUT) {
      await this.update();
      this.close();
    }
  }

  public async create() {
    const coupon = {
      id: this.couponId,
      name: this.couponName,
      duration: this.couponDuration,
      ...(this.couponRedeemBy ? { redeem_by: this.couponRedeemBy } : {}),
      ...(this.couponMaxRedemptions ? { max_redemptions: this.couponMaxRedemptions } : {}),
      ...(this.couponCurrency ? { currency: this.couponCurrency } : {}),
      ...(this.couponDurationInMonths ? { duration_in_months: this.couponDurationInMonths } : {}),
      ...(this.couponPercentageOff ? { percent_off: this.couponPercentageOff } : {}),
      ...(this.couponAmountOff ? { amount_off: this.couponAmountOff * StripeDecimalMultiplier.Hundred } : {}),
    };

    try {
      const createdCoupon = await this._rest.post(this.routeApi, coupon);
      this._stripeService.changeCoupon(createdCoupon);
    } catch (error) {
      throw new Error(error.message);
    }
  }

  public async update() {
    try {
      const updatedCoupon = await this._rest.put(`${this.routeApi}/id/${this.couponId}`, {
        id: this.couponId,
        name: this.couponName,
        duration: this.couponDuration,
        ...(this.couponRedeemBy ? { redeem_by: this.couponRedeemBy } : {}),
        ...(this.couponMaxRedemptions ? { max_redemptions: this.couponMaxRedemptions } : {}),
        ...(this.couponCurrency ? { currency: this.couponCurrency } : {}),
        ...(this.couponDurationInMonths ? { duration_in_months: this.couponDurationInMonths } : {}),
        ...(this.couponPercentageOff ? { percent_off: this.couponPercentageOff } : {}),
        ...(this.couponAmountOff ? { amount_off: this.couponAmountOff * StripeDecimalMultiplier.Hundred } : {}),
      });
      this._stripeService.changeCoupon(updatedCoupon);
    } catch (error) {
      throw new Error(error.message);
    }
  }

  public generateCouponCode() {
    const length = 8;
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ123456789';
    let result = '';
    while (result.length < length) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    this.couponId = result;
  }
}
