import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTableModule } from '@angular/material/table';
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipModule } from '@angular/material/tooltip';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ButtonModule } from '../../shared/components/button/button.module';
import { LayoutsModule } from '../../shared/layouts/layouts.module';
import { LayoutComponentsModule } from '../../shared/components/layout/layout-components.module';
import { LoadingIndicatorModule } from '../../shared/components/loading-indicator/loading-indicator.module';
import { UsersComponent } from './users.component';
import { RouterModule } from '@angular/router';
import { AddNewButtonComponent } from './components/add-new-button/add-new-button.component';
import { UsersLayoutsModule } from './components/layouts/users-layouts.module';
import { TableActionIconComponent } from './components/table-action-icon/table-action-icon.component';
import { TableHeaderComponent } from './components/table-header/table-header.component';
import { TableSearchInputComponent } from './components/table-search-input/table-search-input.component';
import { TableStatusItemComponent } from './components/table-status-item/table-status-item.component';
import { TabsComponent } from './components/tabs/tabs.component';
import { ClientsListComponent } from './menus/clients-menu/clients-list/clients-list.component';
import { ClientsMenuComponent } from './menus/clients-menu/clients-menu.component';
import { TableAddProgramButtonComponent } from './components/table-add-program-button/table-add-program-button.component';
import { StudentsListComponent } from './menus/clients-menu/students-list/students-list.component';
import { EmptyStateComponent } from './components/empty-state/empty-state.component';
import { DialogsModule } from '../../shared/dialogs/dialogs.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TableProgramTagComponent } from './components/table-program-tag/table-program-tag.component';
import { NeuralignBadgeComponent } from './components/neuralign-badge/neuralign-badge.component';
import { NoProgramBadgeComponent } from './components/no-program-badge/no-program-badge.component';
import { customMatToolTipDefaults } from '../../shared/consts/customMatTooTipDefaults';
import { AddStudentButtonComponent } from './components/add-student-button/add-student-button.component';
import { ManagersAndAdminsMenuComponent } from './menus/managers-and-admins-menu/managers-and-admins-menu.component';
import { ManagersListComponent } from './menus/managers-and-admins-menu/managers-list/managers-list.component';
import { AdminsListComponent } from './menus/managers-and-admins-menu/admins-list/admins-list.component';
import { B2cMenuComponent } from './menus/b2c-menu/b2c-menu.component';
import { B2cListComponent } from './menus/b2c-menu/b2c-list/b2c-list.component';
import { B2cStudentsListComponent } from './menus/b2c-menu/b2c-students-list/b2c-students-list.component';
import { OrganizationsMenuComponent } from './menus/organizations-menu/organizations-menu.component';
import { OrganizationsListComponent } from './menus/organizations-menu/organizations-list/organizations-list.component';
import { TableWebsiteItemComponent } from './components/table-website-item/table-website-item.component';
import { TableCreditsBadgeItemComponent } from './components/table-credits-badge-item/table-credits-badge-item.component';
import { BreadcrumbModule } from '../../shared/components/breadcrumb/breadcrumb.module';
import { ImportExportComponent } from './components/import-export/import-export.component';
import { AdjustB2CPricesComponent } from './components/adjust-b2c-prices/adjust-b2c-prices.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { ClientListDialogComponent } from './components/manage-user/client-list-dialog/client-list-dialog-component';
import { CouponUserComponent } from './components/coupon-user/coupon-user.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { ManageUserComponent } from './components/manage-user/manage-user.component';
import { RegisterFormModule } from 'src/app/shared/components/register/register.module';
import { SelectEnabledThemes } from './components/select-enabled-themes/select-enabled-themes.component';
import { SearchFilterModule } from 'src/app/shared/pipes/search-filter/search-filter.module';
import { PrivacyScreenComponent } from './components/privacy-screen/privacy-screen.component';
import { TutorialModalModule } from 'src/app/shared/components/tutorial-modal/tutorial-modal.module';
import { PromotionalPriceComponent } from 'src/app/shared/dialogs/promotional-price/promotional-price.component';

@NgModule({
  declarations: [
    UsersComponent,
    TabsComponent,
    ClientsListComponent,
    ClientsMenuComponent,
    StudentsListComponent,
    TableActionIconComponent,
    AddNewButtonComponent,
    TableStatusItemComponent,
    TableSearchInputComponent,
    TableHeaderComponent,
    TableAddProgramButtonComponent,
    EmptyStateComponent,
    TableProgramTagComponent,
    NeuralignBadgeComponent,
    NoProgramBadgeComponent,
    AddStudentButtonComponent,
    ManagersAndAdminsMenuComponent,
    ManagersListComponent,
    AdminsListComponent,
    B2cMenuComponent,
    B2cListComponent,
    B2cStudentsListComponent,
    OrganizationsMenuComponent,
    OrganizationsListComponent,
    TableWebsiteItemComponent,
    TableCreditsBadgeItemComponent,
    ImportExportComponent,
    AdjustB2CPricesComponent,
    ManageUserComponent,
    ClientListDialogComponent,
    CouponUserComponent,
    SelectEnabledThemes,
    PrivacyScreenComponent,
    PromotionalPriceComponent,
  ],
  imports: [
    CommonModule,
    LayoutsModule,
    MatTableModule,
    LayoutComponentsModule,
    FontAwesomeModule,
    MatTooltipModule,
    ButtonModule,
    LoadingIndicatorModule,
    UsersLayoutsModule,
    RouterModule,
    DialogsModule,
    MatProgressSpinnerModule,
    BreadcrumbModule,
    LayoutsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatCheckboxModule,
    FormsModule,
    MatInputModule,
    ReactiveFormsModule,
    LayoutComponentsModule,
    MatDatepickerModule,
    RegisterFormModule,
    SearchFilterModule,
    TutorialModalModule,
  ],
  providers: [{ provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: customMatToolTipDefaults }],
})
export class UsersModule {}
