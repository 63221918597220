import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { get } from 'lodash';

@Component({
  selector: 'app-client-list-dialog',
  templateUrl: './client-list-dialog.component.html',
  styleUrls: ['./client-list-dialog.component.scss'],
})
export class ClientListDialogComponent implements OnInit {
  dataSource: MatTableDataSource<any>;
  displayedColumns: string[] = ['name', 'enabled'];

  public checked = true;
  public unchecked = false;

  constructor(public dialogRef: MatDialogRef<ClientListDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource(this.data.patrons);
  }

  clickIfClientIsAllowed(patron): boolean {
    const allowedClients = get(this.data, 'allowedClients', []);

    return allowedClients.find((id) => id === patron.id);
  }

  addEnabledClient(patron) {
    const findClient = this.data.allowedClients.find((o) => o === patron.id);

    if (findClient) {
      const index = this.data.allowedClients.indexOf(patron.id);
      this.data.allowedClients.splice(index, 1);
    } else {
      this.data.allowedClients.push(patron.id);
    }
  }

  saveClientList() {
    this.dialogRef.close(this.data.allowedClients);
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    this.dataSource.filterPredicate = (data, filter: string): boolean => {
      if (data) {
        return data.givenName.toLowerCase().includes(filter);
      }
    };
  }
}
