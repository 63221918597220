<app-main-layout [breadcrumbs]="[{ name: 'Stripe', url: '/stripe' }]">
  <div class="topnav">
    <span>Products List</span>
    <div class="actions">
      <input
        type="text"
        placeholder="Search products..."
        [(ngModel)]="searchTerm"
        (input)="search()"
      />
      <app-add-button
        [enable]="'true'"
        [service]="'product'"
        [routeApi]="'/stripe/product'"
        [typeHttp]="'POST'"
      >
      </app-add-button>
    </div>
  </div>

  <body>
    <app-stripe-dashboard
      [columns]="[
        'name',
        'description',
        'currency',
        'unit_amount',
        'interval',
        'active'
      ]"
      [rows]="products"
      [routeApi]="routeApi"
    ></app-stripe-dashboard>
  </body>
</app-main-layout>
