<div class="d-flex justify-content-center">
  <mat-form-field
    appearance="standard"
    class="col-8 px-0 mx-auto"
  >
    <mat-label>Filter</mat-label>
    <input
      matInput
      (keyup)="applyFilter($event)"
      #input
    />
  </mat-form-field>
</div>

<table
  *ngIf="dataSource"
  mat-table
  [dataSource]="dataSource"
  class="col-12 px-0"
>
  <ng-container matColumnDef="name">
    <th
      class="text-center"
      mat-header-cell
      *matHeaderCellDef
    >
      Name
    </th>
    <td
      class="text-center"
      mat-cell
      *matCellDef="let client"
    >
      {{ client.givenName }}
    </td>
  </ng-container>

  <ng-container matColumnDef="enabled">
    <th
      class="text-center"
      mat-header-cell
      *matHeaderCellDef
    >
      Enabled
    </th>
    <td
      class="text-center"
      mat-cell
      *matCellDef="let patron"
    >
      <mat-checkbox
        (click)="addEnabledClient(patron)"
        *ngIf="!clickIfClientIsAllowed(patron)"
        [(ngModel)]="unchecked"
      >
      </mat-checkbox>

      <mat-checkbox
        (click)="addEnabledClient(patron)"
        *ngIf="clickIfClientIsAllowed(patron)"
        [(ngModel)]="checked"
      >
      </mat-checkbox>
    </td>
  </ng-container>

  <tr
    mat-header-row
    *matHeaderRowDef="displayedColumns"
  ></tr>
  <tr
    mat-row
    *matRowDef="let row; columns: displayedColumns"
  ></tr>

  <tr
    class="mat-row"
    *matNoDataRow
  >
    <td
      class="mat-cell"
      colspan="4"
    >
      No data matching the filter "{{ input.value }}"
    </td>
  </tr>
</table>

<div class="my-4 text-center w-100">
  <app-simple-button
    (click)="saveClientList()"
    text="Save"
  >
  </app-simple-button>
</div>
