import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { RestAPIService } from 'src/app/services/rest/rest-api.service';
import { StripeObservableService } from '../stripe-observable.service';
import { StripeTax } from 'src/app/shared/interfaces/Stripe.interfaces';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-tax-stripe',
  templateUrl: './tax-stripe.component.html',
  styleUrls: ['./tax-stripe.component.scss'],
})
export class TaxStripeComponent implements OnInit {
  public taxes: StripeTax[];
  public originalTaxes: StripeTax[];

  //Config
  searchTerm: string;
  public routeApi = 'stripe/tax';

  constructor(
    private _rest: RestAPIService,
    public dialog: MatDialog,
    private _stripeService: StripeObservableService,
    public _snackBar: MatSnackBar,
  ) {}

  async ngOnInit() {
    await this.getTaxes();
    this.originalTaxes = Array.isArray(this.taxes) ? [...this.taxes] : [];
    this._stripeService.currentTax.subscribe(async (newTax) => {
      if (newTax) {
        await this.handleTableTaxes(newTax);
      }
    });
    this.search();
  }

  async handleTableTaxes(newTax: StripeTax) {
    if (newTax.active === false) {
      this.taxes = this.taxes.filter((tax: StripeTax) => tax.id !== newTax.id);
    } else {
      const existingTaxIndex = this.taxes.findIndex((tax: StripeTax) => tax.id === newTax.id);

      if (existingTaxIndex !== -1) {
        this.taxes[existingTaxIndex] = newTax;
      } else {
        this.taxes.push(newTax);
      }
    }
    this.originalTaxes = [...this.taxes];
    this.search();
  }

  public async getTaxes() {
    try {
      const taxes = await this._rest.get(this.routeApi + '/active');
      this.taxes = taxes.data;
    } catch (error) {
      this._snackBar.open(`${error.message}!`, 'Close', {
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
    }
  }

  search() {
    this.taxes = this.handleTaxSearch(this.originalTaxes, this.searchTerm);
  }

  public handleTaxSearch(items: StripeTax[], searchTerm: string): StripeTax[] {
    if (!items) return [];
    if (!searchTerm) return items;

    searchTerm = searchTerm.toLowerCase();

    return items.filter((it) => {
      return (
        (it.display_name ? it.display_name.toLowerCase().includes(searchTerm) : false) ||
        (it.description ? it.description.toLowerCase().includes(searchTerm) : false) ||
        (it.percentage ? it.percentage.toString().includes(searchTerm) : false) ||
        (it.country ? it.country.toLowerCase().includes(searchTerm) : false) ||
        (it.inclusive ? it.inclusive.toString().includes(searchTerm) : false) ||
        (it.active ? it.active.toString().includes(searchTerm) : false)
      );
    });
  }
}
