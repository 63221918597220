<app-game
  *ngIf="!loading && showGame"
  [queryData]="queryData"
  [student]="gameStudentDTO"
  [overwriteSessionDetails]="overwriteSessionDetails"
></app-game>

<div
  class="loading"
  *ngIf="loading && !showGame"
>
  <div class="col-6 px-0">
    <div class="col-12 px-0 loadingText">
      <img
        [ngStyle]="{ width: '160px', padding: '10px' }"
        src="../../../../assets/img/NeurAlign text logo.png"
      />
    </div>
    <div class="col-4 px-0 m-auto">
      <mat-progress-bar
        class="readLsColors"
        mode="indeterminate"
      ></mat-progress-bar>
    </div>
  </div>
</div>
