import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { LoggerService } from 'src/app/services/logger/logger.service';
import { RestAPIService } from 'src/app/services/rest/rest-api.service';

interface Manager {
  name: string;
  emailVerified?: string;
  id: string;
  patron?: any;
}

@Injectable({
  providedIn: 'root',
})
export class ManagersListService {
  managers: any[];

  constructor(
    private _logger: LoggerService,
    private _rest: RestAPIService,
    private _router: Router,
    private _snackBar: MatSnackBar,
  ) {}

  public async getManagers(options?: { refresh: boolean }): Promise<Manager[]> {
    try {
      if (options?.refresh || !this.managers) {
        const response = await this._rest.get('organization/self/managers');

        this.managers = response.managers
          .filter((user) => user.role === 'manager')
          .filter((user) => user.patron)
          .map((manager) => {
            return {
              ...manager,
              name: manager.patron.givenName,
              status: transformManagerStatus(manager),
              type: 'Manager',
            };
          });
      }

      return this.managers;
    } catch (err) {
      this._logger.error(err);
    }
  }

  public editManager(manager: Manager): void {
    localStorage.setItem('LS_OrgEditingUser', JSON.stringify(manager));
    this._router.navigate(['/users/manage/' + manager.patron.id]);
  }
  public resendManagerVerificationEmail(manager: any): void {
    const managerName = manager.fullname ? manager.fullname : manager.name;

    this._rest
      .get('account/email/verify/resend/' + manager.accountId, {
        msg: 'Could not get account/email/resend/:managerAccountId.',
      })
      .then(() => {
        this._snackBar.open(`Verification email has been sent to ${managerName}`, 'Close', {
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      })
      .catch(() => {
        this._snackBar.open('E-mail not found', 'Close', {
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      });
  }
}

function transformManagerStatus(manager: Manager) {
  if (manager.emailVerified) {
    return 'active';
  } else if (!manager.emailVerified) {
    return 'not-active';
  } else if (manager.emailVerified === undefined) {
    return 'error';
  }
}
