import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ManagersListService } from './managers-list.service';
import { LoggerService } from 'src/app/services/logger/logger.service';
import { ConfirmationService } from 'src/app/services/confirmation/confirmation.service';
import { RestAPIService } from 'src/app/services/rest/rest-api.service';
import { UsersHelperService } from '../../../users-helper.service';

@Component({
  selector: 'users-managers-list',
  templateUrl: './managers-list.component.html',
  styleUrls: ['./managers-list.component.scss'],
})
export class ManagersListComponent implements OnInit {
  protected template: 'loading' | 'table' = 'table';
  protected dataSource = new MatTableDataSource();

  constructor(
    private _managersListService: ManagersListService,
    private _logger: LoggerService,
    private _confirm: ConfirmationService,
    private _rest: RestAPIService,
    private _usersService: UsersHelperService,
  ) {}

  async ngOnInit() {
    this.template = 'loading';
    const data = await this._managersListService.getManagers();
    this.dataSource = new MatTableDataSource(data);
    this.template = 'table';
  }

  public editManager(manager: any) {
    this._managersListService.editManager(manager);
  }
  public resendEmailVerification(manager: any) {
    this._managersListService.resendManagerVerificationEmail(manager);
  }
  public deleteManager(manager: any) {
    this._confirm
      .createConfirmation('Warning', 'Are you sure you want to delete this manager?', 'Yes', 'No')
      .then(async () => {
        try {
          await this._rest.delete('patron/' + manager.patron.id, { msg: 'Could not delete manager.' });

          const data = await this._managersListService.getManagers({ refresh: true });
          this.dataSource = new MatTableDataSource(data);
        } catch (err) {
          this._logger.error(err);
        }
      });
  }

  public applyFilter(event: any): void {
    let { value } = event.target;
    value = value.trim();
    value = value.toLowerCase();
    this.dataSource.filter = value;
  }

  getEmailVerifiedTooltip(isEmailVerified: boolean | undefined): string {
    return this._usersService.getEmailVerifiedTooltip(isEmailVerified);
  }

  public isDataSourceEmpty(): boolean {
    return this.dataSource.data.length === 0;
  }
}
