<app-main-layout [breadcrumbs]="[{ name: 'Stripe', url: '/stripe' }]">
  <div class="topnav">
    <span>Coupons List</span>
    <div class="actions">
      <input
        type="text"
        placeholder="Search coupons..."
        [(ngModel)]="searchTerm"
        (input)="search()"
      />
      <!-- 
        Button currently disabled because coupons are still created on the old page. 
        Uncomment and enable this when the old page is deactivated. 
      -->
      <!-- <app-add-button
        [enable]="'true'"
        [service]="'coupon'"
        [routeApi]="'/stripe/coupon'"
        [typeHttp]="'POST'"
      >
      </app-add-button> -->
    </div>
  </div>

  <body>
    <!-- 
      Columns disabled due to the old business rule being incompatible with Stripe variables. 
      Uncomment and enable them when the old coupons page is deactivated.
      'name',
      'duration_in_months',
     -->
    <app-stripe-dashboard
      [columns]="[
        'id',
        'amount_off',
        'percent_off',
        'duration',
        'max_redemptions'
      ]"
      [rows]="coupons"
      [routeApi]="routeApi"
    ></app-stripe-dashboard>
  </body>
</app-main-layout>
