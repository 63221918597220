import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth/auth.service';
import { get } from 'lodash';
import { User } from 'src/app/shared/models/user.model';
import { RestAPIService } from 'src/app/services/rest/rest-api.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IconDefinition, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { CloudnaryService } from 'src/app/services/cloudnary/cloudnary.service';
import { Event } from 'src/app/shared/interfaces/portal.interface';

@Component({
  selector: 'app-org-signup-completion.component',
  templateUrl: './org-signup-completion.component.html',
  styleUrls: ['./org-signup-completion.component.scss'],
})
export class OrganizationSignupComplete implements OnInit {
  public loading = true;
  public orgFormGroup: FormGroup;
  public user: User;
  public updatingOrg = false;
  public readonly spinnerIcon: IconDefinition = faSpinner;
  public allowTermsAgree = false;

  constructor(
    private auth: AuthService,
    private rest: RestAPIService,
    private _router: Router,
    private snackbar: MatSnackBar,
    private cloudnaryService: CloudnaryService,
  ) {}

  async ngOnInit() {
    this.user = await this.auth.getUser();
    this.orgFormGroup = await this._createOrgFormGroup();

    this.loading = false;
  }

  private async uploadImage() {
    const imageUrl = await this.cloudnaryService.getUploadedVideoUrl();
    this.orgFormGroup.get('logo').setValue(imageUrl);
  }

  private async _createOrgFormGroup(): Promise<FormGroup> {
    return new FormGroup({
      name: new FormControl(get(this.user, 'organization.name', ''), Validators.required),
      subdomain: new FormControl(get(this.user, 'organization.subdomain', ''), Validators.required),
      referredBy: new FormControl(''),
      phoneNumber: new FormControl('', Validators.required),
      position: new FormControl('', Validators.required),
      professionalAssociation: new FormControl(''),
      logo: new FormControl(get(this.user, 'organization.logo', '').replace('http://', 'https://')),
      address: new FormGroup({
        address: new FormControl('', Validators.required),
        postalCode: new FormControl('', Validators.required),
        country: new FormControl({ value: '', disabled: true }, Validators.required),
        province: new FormControl({ value: '', disabled: true }, Validators.required),
      }),
      acceptedTerms: new FormControl(false, Validators.requiredTrue),
    });
  }

  public handleAddressChange(address) {
    const components = address.address_components;

    if (components) {
      const country = components.find((c) => c.types.includes('country'));
      const province = components.find((c) => c.types.includes('administrative_area_level_1'));

      this.orgFormGroup.get('address').get('country').setValue(get(country, 'long_name', ''));
      this.orgFormGroup.get('address').get('province').setValue(get(province, 'long_name', ''));
    }
  }

  public async updateOrganization() {
    try {
      const organization = this.orgFormGroup.getRawValue();
      this.updatingOrg = true;
      this.rest.put('/organization/completeRegistration', organization).then(async (result) => {
        if (!result) {
          this.updatingOrg = false;
          return;
        }

        await this.auth.getUser(true);
        this._router.navigate(['programs-pricing']);
      });
    } catch (error) {
      this.updatingOrg = false;
      this.snackbar.open(error.message, 'Close', {
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
    }
  }

  public uploadToCloudinary() {}

  public fillSubdomain(): void {
    const orgName = this.orgFormGroup.get('name').value;
    if (orgName && orgName.trim() !== '') {
      this.orgFormGroup.get('subdomain').setValue(this.getOrgSubdomain(orgName));
    }
  }

  private getOrgSubdomain(orgName: string): string {
    return orgName
      .toLowerCase()
      .split(' ')
      .join('')
      .replace(/[^a-zA-Z0-9]/g, '');
  }

  onScroll(event: Event) {
    const element = event.target;
    const offSet = 100;
    const height = element.clientHeight;
    const heightToCheck = height + offSet;

    if (element.scrollHeight - element.scrollTop <= heightToCheck) {
      this.allowTermsAgree = true;
    }
  }
}
