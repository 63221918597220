import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { Component, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';

import { Client } from 'src/app/shared/interfaces';
import { ClientsListService } from './clients-list.service';
import { UsersHelperService } from '../../../users-helper.service';
import { ConfirmationService } from 'src/app/services/confirmation/confirmation.service';
import { MatDialog } from '@angular/material/dialog';
import { AlertDialogComponent } from 'src/app/shared/dialogs/alert/alert.dialog';

const CLIENT_SORT_ORDER_KEY = 'clientSortOrder';

@Component({
  selector: 'users-clients-list',
  templateUrl: './clients-list.component.html',
  styleUrls: ['./clients-list.component.scss'],
})
export class ClientsListComponent {
  dataSource: MatTableDataSource<any>;
  currentClientId: string;
  template: 'table' | 'loading';
  sortOrderLabel = this._getSortOrderLabel();
  loadingClientId: string | null = null;

  @Output() onGetStudents = new EventEmitter();

  constructor(
    private _clientsListService: ClientsListService,
    private _confirm: ConfirmationService,
    private _usersService: UsersHelperService,
    private _router: Router,
    private cdr: ChangeDetectorRef,
    private _dialog: MatDialog,
  ) {}

  async ngOnInit(): Promise<void> {
    this.template = 'loading';
    await this.loadClientData();
    this.template = 'table';
  }

  private async loadClientData() {
    const data = await this._clientsListService.getClients({ refresh: true });
    if (this.getClientSortOrder() === 'asc') {
      this.dataSource = new MatTableDataSource(data);
    } else {
      this.dataSource = new MatTableDataSource(data.reverse());
    }
  }

  public getStudents(id: string): void {
    this.onGetStudents.emit(id);
  }

  public addStudent(id: string): void {
    this._clientsListService.addClientStudent(id);
  }

  public editClient(client: Client): void {
    localStorage.setItem('LS_OrgEditingUser', JSON.stringify(client));
    this._router.navigate(['/users/manage/' + client.id]);
  }

  public resendVerificationEmail(user: any): void {
    this._clientsListService.resendClientVerificationEmail(user);
  }

  public async deleteClient(id): Promise<void> {
    const warningMessage =
      'Are you sure you want to delete this client? All students associated with this client will also be deleted.';
    this._confirm
      .createConfirmation('Warning', warningMessage, 'Yes', 'No')
      .then(async () => {
        this.template = 'loading';
        await this._clientsListService.deleteClient(id);
      })
      .then(async () => {
        await this.loadClientData();
        this.template = 'table';
      });
  }

  public getEmailVerifiedTooltip(isEmailVerified: boolean | undefined): string {
    return this._usersService.getEmailVerifiedTooltip(isEmailVerified);
  }

  public applyFilter(event: any): void {
    let { value } = event.target;
    value = value.trim();
    value = value.toLowerCase();
    this.dataSource.filter = value;
  }

  public isDataSourceEmpty(): boolean {
    return this.dataSource.data.length === 0;
  }

  private toggleClientListSortOrder(): void {
    if (this.getClientSortOrder() === 'asc') {
      window.localStorage.setItem(CLIENT_SORT_ORDER_KEY, 'desc');
    } else {
      window.localStorage.setItem(CLIENT_SORT_ORDER_KEY, 'asc');
    }
  }

  private getClientSortOrder(): string {
    return window.localStorage.getItem(CLIENT_SORT_ORDER_KEY);
  }

  public async reverseClientListOrder() {
    this.toggleClientListSortOrder();
    await this.loadClientData();
    this.sortOrderLabel = this._getSortOrderLabel();
  }

  private _getSortOrderLabel() {
    const currentSortOrder = this.getClientSortOrder();
    if (currentSortOrder === 'asc') {
      return 'Sorting oldest first';
    } else {
      return 'Sorting newest first';
    }
  }

  public downloadStudentFile(): void {
    this._clientsListService.downloadStudentFile();
  }

  public async importStudentsForFile(e: any, client: Client) {
    this.loadingClientId = client.id;
    this.cdr.detectChanges();

    const checkIsFinished = await this._clientsListService.importStudentsForFile(e, client);
    if (checkIsFinished) {
      this._dialog.open(AlertDialogComponent, {
        width: '400px',
        data: 'All users have been created',
      });
      setTimeout(() => {
        this.loadingClientId = null;
        this.cdr.detectChanges();
      }, 1000);
    } else {
      this._dialog.open(AlertDialogComponent, {
        width: '400px',
        data: 'Error importing users',
      });
    }
  }
}
