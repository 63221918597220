import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { RestAPIService } from 'src/app/services/rest/rest-api.service';
import { Organization } from 'src/app/shared/models';
import { B2cPaymentTypes } from './constants/b2c-prices.constants';

@Component({
  selector: 'app-adjust-b2c-prices',
  templateUrl: './adjust-b2c-prices.component.html',
  styleUrls: ['./adjust-b2c-prices.component.scss'],
})
export class AdjustB2CPricesComponent implements OnInit {
  public paymentOptions = [
    {
      type: B2cPaymentTypes.SinglePayment,
      toolTip: 'A one time payment for the client, for the full cost of the program, single use.',
      callback: () => {
        this.organization.allowB2cSplitPayments = false;
        this.organization.b2cPaymentType = B2cPaymentTypes.SinglePayment;
      },
    },
    {
      type: B2cPaymentTypes.SplittedPayment,
      toolTip:
        'The client will enter a 12 week or 3 month payment plan at an additional cost of 20% over the one-time payment. The client will have access to the program one time.',
      callback: () => {
        this.organization.allowB2cSplitPayments = true;
        this.organization.b2cPaymentType = B2cPaymentTypes.SplittedPayment;
      },
    },
    {
      type: B2cPaymentTypes.MonthlyInstallmentPayment,
      toolTip:
        'The client will pay a monthly subscription fee for unlimited recurring access to the program. A single credit will be used for the length of the subscription.',
      callback: () => {
        this.organization.allowB2cSplitPayments = false;
        this.organization.b2cPaymentType = B2cPaymentTypes.MonthlyInstallmentPayment;
      },
    },
  ];

  public plusLine: IconDefinition = faPlus;
  public price = 100;
  public currency: string;
  public errorMsg = '';
  public allowSplitPayments = false;
  public organization: Organization;
  public loading = false;
  public paymentTypes = B2cPaymentTypes;
  public selectedPaymentType = B2cPaymentTypes.SinglePayment;

  constructor(
    public dialogRef: MatDialogRef<AdjustB2CPricesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private rest: RestAPIService,
    protected _snackBar: MatSnackBar,
  ) {}

  async ngOnInit() {
    if (this.data.organization) {
      this.organization = this.data.organization;
      this.currency = this.data.organization.b2cCurrency;
      this.price = this.data.organization.b2cTokenPrice;
      this.allowSplitPayments = this.organization.allowB2cSplitPayments
        ? this.organization.allowB2cSplitPayments
        : false;

      await this.setAllowedPaymentOptions();

      this.setPaymentType();
    } else {
      this._snackBar.open(`Something went wrong please try again later`, 'Close', {
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });

      this.dialogRef.close();
    }
  }

  async setAllowedPaymentOptions() {
    if (!this.organization.b2cAllowedPaymentTypes) {
      this.organization.b2cAllowedPaymentTypes = [B2cPaymentTypes.SinglePayment, B2cPaymentTypes.SplittedPayment];
    }

    this.paymentOptions = this.paymentOptions.filter((p) => this.organization.b2cAllowedPaymentTypes.includes(p.type));

    const selectedPaymentOption = this.paymentOptions.find((p) => this.organization.b2cPaymentType === p.type);

    // if the org selected payment option is no longer allowed, change it to default and save
    if (!selectedPaymentOption) {
      this.organization.b2cPaymentType = B2cPaymentTypes.SinglePayment;
      await this.updateOrganization();
    }
  }

  setPaymentType() {
    if (this.organization.b2cPaymentType) {
      this.selectedPaymentType = this.organization.b2cPaymentType;
    } else {
      this.selectedPaymentType = this.allowSplitPayments
        ? B2cPaymentTypes.SplittedPayment
        : B2cPaymentTypes.SinglePayment;
    }
  }

  getB2cPaymentOptions(type: string) {
    return this.paymentTypes[type];
  }

  onChange(): void {
    const price = Number(this.price);

    if (typeof price !== 'number') {
      this.errorMsg = 'Invalid price.';
    }

    this.errorMsg = '';
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  public async toggleB2cSplitPayments() {
    try {
      this.organization.allowB2cSplitPayments = this.organization.allowB2cSplitPayments
        ? !this.organization.allowB2cSplitPayments
        : true;

      this.rest.put(
        `organization/toggleSplitPayments/` + this.organization.id,
        {
          toggleSplitPayment: this.organization.allowB2cSplitPayments,
        },
        { msg: 'Could not put organization.' },
      );
    } catch (err) {
      this._snackBar.open(`${err.error.message}`, 'Close', {
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
    }
  }

  public async updateOrganization() {
    try {
      this.rest.put('organization/update', this.organization);
    } catch (err) {
      this._snackBar.open(`${err.error.message}`, 'Close', {
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
    }
  }

  async onConfirm(): Promise<void> {
    if (!this.price) {
      this.errorMsg = 'Invalid price.';
      return;
    }

    const price = parseFloat(this.price.toString());
    const b2cCampaign = {
      price,
      currency: this.currency,
    };

    await this.updateOrganization();
    this.dialogRef.close(b2cCampaign);
  }
}
