import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { RestAPIService } from 'src/app/services/rest/rest-api.service';
import { StripeObservableService } from '../stripe-observable.service';
import { StripeProduct } from 'src/app/shared/interfaces/Stripe.interfaces';
import { MatSnackBar } from '@angular/material/snack-bar';
@Component({
  selector: 'app-product-stripe',
  templateUrl: './product-stripe.component.html',
  styleUrls: ['./product-stripe.component.scss'],
})
export class ProductStripeComponent implements OnInit {
  public products: StripeProduct[];
  public originalProducts: StripeProduct[];
  public routeApi = 'stripe/product';

  //Config
  searchTerm: string;

  constructor(
    private _rest: RestAPIService,
    public dialog: MatDialog,
    private _stripeService: StripeObservableService,
    public _snackBar: MatSnackBar,
  ) {}

  async ngOnInit() {
    await this.getProductsWithPrice();
    this.originalProducts = Array.isArray(this.products) ? [...this.products] : [];
    this._stripeService.currentProduct.subscribe(async (newProduct) => {
      if (newProduct) {
        await this.handleTableProducts(newProduct);
      }
    });
    this.search();
  }

  async handleTableProducts(newProduct: StripeProduct) {
    if (newProduct.active === false) {
      this.products = this.products.filter((product: StripeProduct) => product.id !== newProduct.id);
    } else {
      const createdProduct = await this.insertPriceInProduct(newProduct);
      const existingProductIndex = this.products.findIndex(
        (product: StripeProduct) => product.id === createdProduct.id,
      );

      if (existingProductIndex !== -1) {
        this.products[existingProductIndex] = createdProduct;
      } else {
        this.products.push(createdProduct);
      }
    }
    this.originalProducts = [...this.products];

    this.search();
  }

  public async getProductsWithPrice() {
    try {
      const products = await this._rest.get(this.routeApi + '/active');
      const updatedProducts = await Promise.all(
        products.data.map(async (product) => {
          return this.insertPriceInProduct(product);
        }),
      );
      this.products = updatedProducts;
    } catch (error) {
      throw new Error(error.message);
    }
  }

  public async insertPriceInProduct(product) {
    if (!product.default_price) return product;
    const result = await this.getProductPrices(product.default_price);

    const updatedProduct = { ...product };

    updatedProduct['currency'] = result.currency;
    updatedProduct['unit_amount'] = result.unit_amount;
    updatedProduct['interval'] = result.recurring?.interval;

    return updatedProduct;
  }

  public async getProductPrices(id: string) {
    try {
      const prices = await this._rest.get(`stripe/price/id/${id}`);
      return prices;
    } catch (error) {
      this._snackBar.open(`${error.message}!`, 'Close', {
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
    }
  }

  public async deleteProduct(id: string) {
    await this._rest.delete(`stripe/product/id/${id}`, {});

    this.products = this.products.filter((product: StripeProduct) => product.id !== id);
  }

  search() {
    this.products = this.handleProductSearch(this.originalProducts, this.searchTerm);
  }

  public handleProductSearch(items: any, searchTerm: string) {
    if (!items) return [];
    if (!searchTerm) return items;

    searchTerm = searchTerm.toLowerCase();

    return items.filter((it) => {
      return (
        (it.name ? it.name.toLowerCase().includes(searchTerm) : false) ||
        (it.description ? it.description.toLowerCase().includes(searchTerm) : false) ||
        (it.currency ? it.currency.toString().includes(searchTerm) : false) ||
        (it.unit_amount ? it.unit_amount.toString().includes(searchTerm) : false) ||
        (it.interval ? it.interval.toString().includes(searchTerm) : false) ||
        (it.active ? it.active.toString().includes(searchTerm) : false)
      );
    });
  }
}
