import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';

declare let cloudinary: any;

@Injectable({
  providedIn: 'root',
})
export class CloudnaryService {
  constructor(private _snackBar: MatSnackBar) {}

  public async getUploadedVideoUrl() {
    return new Promise((resolve) => {
      cloudinary.openUploadWidget(this.getCloudnaryConfig(), (error, result) => {
        if (result) {
          const [imageResponse] = result;
          const { secure_url, url } = imageResponse;

          resolve(secure_url || url);
        } else if (error && error.message !== 'User closed widget') {
          this._snackBar.open('There was an error while uploading the image, please try again later', 'OK', {
            duration: 5000,
          });
        }
      });
    });
  }

  private getCloudnaryConfig() {
    return {
      cloud_name: environment.CLOUDINARY_CLOUD_NAME,
      upload_preset: environment.CLOUDINARY_UPLOAD_PRESET,
      secure: true,
    };
  }
}
