import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { clone } from 'lodash';
import { B2cPaymentTypes } from 'src/app/pages/users/components/adjust-b2c-prices/constants/b2c-prices.constants';
import { Organization } from 'src/app/shared/models';

@Component({
  selector: 'app-b2c-payment-types',
  templateUrl: './b2c-payment-types.component.html',
  styleUrls: ['./b2c-payment-types.component.scss'],
})
export class B2cPaymentTypesDialog implements OnInit {
  public displayedColumns: string[] = ['name', 'enabled'];
  public dataSource: MatTableDataSource<string>;

  public checked = true;
  public unchecked = false;

  public paymentTypes = B2cPaymentTypes;
  public orgPaymentTypes: string[];

  constructor(
    public dialogRef: MatDialogRef<B2cPaymentTypesDialog>,
    @Inject(MAT_DIALOG_DATA) public data: Organization,
  ) {}

  async ngOnInit() {
    this.dataSource = new MatTableDataSource(Object.keys(this.paymentTypes));
    this.orgPaymentTypes = clone(this.data.b2cAllowedPaymentTypes);
  }

  public changeEnabledStatus(paymentType: string) {
    const b2cPaymentType = this.data.b2cAllowedPaymentTypes.includes(B2cPaymentTypes[paymentType]);

    if (!b2cPaymentType) {
      this.data.b2cAllowedPaymentTypes.push(B2cPaymentTypes[paymentType]);
    } else {
      const selectedPaymentType = this.data.b2cAllowedPaymentTypes.find((v) => v === paymentType);
      const index = this.data.b2cAllowedPaymentTypes.indexOf(selectedPaymentType);
      this.data.b2cAllowedPaymentTypes.splice(index, 1);
    }
  }

  public checkIfPaymentIsEnabled(paymentType: string): boolean {
    const b2cPaymentType = this.data.b2cAllowedPaymentTypes;

    return b2cPaymentType.includes(B2cPaymentTypes[paymentType]);
  }

  public isDefaultPaymentType(paymentType: string) {
    return B2cPaymentTypes[paymentType] === B2cPaymentTypes.SinglePayment;
  }

  public confirm() {
    this.dialogRef.close(true);
  }

  public cancel() {
    // reset the payment types array
    this.data.b2cAllowedPaymentTypes = this.orgPaymentTypes;
    this.dialogRef.close();
  }
}
