import { Injectable } from '@angular/core';
import { RestAPIService } from 'src/app/services/rest/rest-api.service';

import { getUsStates } from 'src/app/shared/helpers/getUsStates';
import { getCountries } from 'src/app/shared/helpers/getCountries.helper';
import { getCanadianStates } from 'src/app/shared/helpers/getCanadianStates.helper';

@Injectable({
  providedIn: 'root',
})
export class ModalStripeService {
  constructor(private _rest: RestAPIService) {}

  public async getTax() {
    const taxes = await this._rest.get('stripe/tax/active');
    if (!taxes) return;
    return taxes.data;
  }

  public async getProducts() {
    const products = await this._rest.get('stripe/product/active');
    if (!products) return;
    return products.data;
  }

  async findProductById(priceProduct: string) {
    const products = await this.getProducts();
    if (products.length > 0) {
      return (priceProduct = products.find((product) => product.id === priceProduct).id);
    }
  }

  async getOrganizations() {
    const response = await this._rest.get('admin/users');
    const users = response.users.filter((res) => res.organization !== undefined);
    const organizations = users.map((org) => {
      return {
        id: org.organization.id,
        value: org.organization.name,
      };
    });
    return organizations;
  }

  public getUsStates() {
    return getUsStates;
  }

  public getCountries() {
    return getCountries;
  }

  public getCanadianStates() {
    return getCanadianStates;
  }
}
