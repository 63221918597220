import { Router } from '@angular/router';
import { EventEmitter, Injectable } from '@angular/core';

import { IOutsiderOrg, Organization, Outsider } from 'src/app/shared/interfaces';
import { UsersHelperService } from '../../../users-helper.service';
import { LoggerService } from 'src/app/services/logger/logger.service';
import { RestAPIService } from 'src/app/services/rest/rest-api.service';

@Injectable({
  providedIn: 'root',
})
export class OrganizationsListService {
  organizations;

  public refreshCredits = new EventEmitter<void>();

  constructor(
    private _logger: LoggerService,
    private _rest: RestAPIService,
    private _usersService: UsersHelperService,
    private _router: Router,
  ) {}

  public refreshOrgCredits() {
    if (this.refreshCredits) {
      this.refreshCredits.emit();
    }
  }

  async getOrganizations(options?: { refresh: boolean }): Promise<IOutsiderOrg[]> {
    try {
      if (options?.refresh || !this.organizations) {
        const response = await this._rest.get(`/organization/self/outsiders`);
        this.organizations = response.outsiders.map((outsider: Outsider) => {
          // We must show only the tokens that are avaliable for use, excluding the ones that are assigned to students and the ones that dont have the payment confirmed

          const outsiderAvaliableTokens = outsider.availableTokens.filter((t) => !t.studentId && t.paymentConfirmed);
          const credits = (outsiderAvaliableTokens.length -= outsider.availableTokens.filter(
            (t) => t.studentId && !t.paymentConfirmed,
          ).length);

          return {
            ...outsider,
            name: outsider.organization.name,
            status: this._usersService.getUserStatus(outsider.emailVerified),
            email: outsider.email,
            webpage: outsider.organization.subdomain,
            credits,
            type: 'Organization',
          };
        });
      }
      return this.organizations;
    } catch (err) {
      this._logger.error(err);
    }
  }

  editOrganization(org: Organization) {
    localStorage.setItem('LS_OrgEditingUser', JSON.stringify(org));
    this._router.navigate(['/users/manage/' + org.id]);
  }
}
