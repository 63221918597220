<div class="empty">
  <!-- empty  div for layout -->
</div>

<div class="tabs">
  <div class="wrapper">
    <button
      class="tab"
      [ngClass]="{ active: activeTab == 'clients' }"
      (click)="switchMenu('clients')"
    >
      Clients
    </button>
    <button
      *ngIf="showB2CTab"
      class="tab"
      [ngClass]="{ active: activeTab == 'b2c' }"
      (click)="switchMenu('b2c')"
    >
      B2C
    </button>
    <button
      *ngIf="showOrganizationsTab"
      class="tab"
      [ngClass]="{ active: activeTab == 'organizations' }"
      (click)="switchMenu('organizations')"
    >
      Organizations
    </button>
    <button
      *ngIf="showManagersAndAdminsTab"
      class="tab"
      [ngClass]="{ active: activeTab == 'managersAndAdmins' }"
      (click)="switchMenu('managersAndAdmins')"
    >
      Admins & Managers
    </button>
  </div>
</div>
