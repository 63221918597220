import { Injectable } from '@angular/core';
import { includes, camelCase, get } from 'lodash';
import { GlobalConfigurationHelper } from 'src/app/services/utils/global-configuration-helper';
import { progressTypes } from 'src/app/shared/consts/global-constants';
import { Progress } from 'src/app/shared/models';
import { LockTypes } from '../program-game.const';
import {
  Theme,
  ThemeCategory,
} from '../../configuration-pages/content-configurations/components/themes/interfaces/themes.interface';
import { Category, UnlockCondition } from '../../configuration-pages/interfaces/global-config.interfaces';
import { GameTag } from '../../demo/constants/demo-constants';
import { ProgressData, QueryData } from 'src/app/shared/components/game/game.interface';
import { VIDEO } from '../consts/program-game-consts';

@Injectable({
  providedIn: 'root',
})
export class ProgramHelper {
  constructor(private globalConfigHelper: GlobalConfigurationHelper) {}

  public async getProgramInfo(theme: Theme) {
    const categories = await this.globalConfigHelper.getCategories();
    const idList = theme.categories.map((c) => c.id);
    const enabledCategories = categories.filter(
      (c) => includes(idList, c.id) || this.findCategoryByName(theme.categories, c.name),
    );

    const bonusPackList = this.getBonusPackList(enabledCategories);

    const programInfo = {
      name: 'Neuralign',
      image: './assets/img/programs/NeurAlign text logo.png',
      categories: enabledCategories,
      bonusPacks: bonusPackList,
    };
    return programInfo;
  }

  // This method is used to allow the program to work even with the older hardcoded categories

  public findCategoryByName(categoriesList: ThemeCategory[], categoryName: string): ThemeCategory {
    return categoriesList.find((c: ThemeCategory) => c.name === categoryName.toLowerCase() && c.enabled);
  }

  public getBonusPackList(category: Category[]) {
    const bonusList = category.reduce((a, b) => {
      if (b.bonusPack && get(b.bonusPack, 'enabled', false)) {
        const bonusCategory = this.createBonusCategory(b);

        a.push(bonusCategory);
      }

      return a;
    }, []);

    return bonusList;
  }

  public createBonusCategory(category: Category) {
    const bonusCategory = {
      name: 'Bonus ' + category.name,
      title: camelCase('Bonus ' + category.name),
      games: category.bonusPack.games,
      unlockCondition: [{ name: category.name, session: category.maxSessions }],
      img: category.bonusPack.img || 'assets/img/Image_Placeholder.jpg',
      lockedImg: category.bonusPack.lockedImg || 'assets/img/Image_Placeholder.jpg',
      maxSessions: category.bonusPack.maxSessions,
      graphType: category.graphType,
      sessionInterval: category.bonusPack.sessionInterval,
      description: category.description,
      id: category.id,
      isBonus: true,
      startingSession: category.bonusPack.startingSession,
    };

    return bonusCategory;
  }

  public shouldUnlockLesson(
    waitByPass: boolean,
    category: Category,
    progress: Progress[],
    themeHasAssessment: boolean,
    assessmentCount: number,
  ): boolean {
    if (waitByPass || this.isLessonAvaliable(category, progress, themeHasAssessment, assessmentCount)) {
      return true;
    }

    return false;
  }

  public checkCategoryUnlockCondition(
    conditions: UnlockCondition[],
    progress: Progress[],
    themeHasAssessment: boolean,
  ): boolean {
    const resultArray: boolean[] = [];
    for (const condition of conditions) {
      if (condition.name.toLowerCase() === progressTypes.ASSESSMENT && !themeHasAssessment) {
        resultArray.push(true);
      } else {
        const prog = progress.find((p) => p.tag === condition.name.toLowerCase() && !p.metadata.savedGame);

        if (!prog) {
          resultArray.push(false);
        } else {
          prog.session >= condition.session ? resultArray.push(true) : resultArray.push(false);
        }
      }
    }

    return resultArray.every((e) => e === true);
  }

  public getFailedUnlockConditions(progress: Progress[], category: Category, assessmentCount: number) {
    const failedConditions = [];
    const categoryProgress = progress.find((p) => p.tag === category.name.toLowerCase() && !p.metadata.savedGame);
    const categorySession = this.getProgressSession(categoryProgress, assessmentCount);
    const maxSessions = category.isBonus ? category.startingSession + category.maxSessions : category.maxSessions;

    for (const condition of category.unlockCondition) {
      const conditionProgress = progress.find((p) => p.tag === condition.name.toLowerCase() && !p.metadata.savedGame);

      if (!conditionProgress || conditionProgress.session < condition.session) {
        failedConditions.push({
          type: LockTypes.session,
          condition,
        });
      }
    }

    if (categorySession === maxSessions) {
      failedConditions.push({
        type: LockTypes.maxSessions,
      });
    } else if (!this.canPlayNextSession(categoryProgress, category) && categorySession < maxSessions) {
      failedConditions.push({
        type: LockTypes.interval,
      });
    }

    return failedConditions;
  }

  public getProgressSession(progress: Progress, assessmentCount: number) {
    const progressTag = get(progress, 'tag', '');

    const session = progressTag === progressTypes.ASSESSMENT ? assessmentCount : get(progress, 'session', 0);
    return session;
  }

  public isLessonAvaliable(
    category: Category,
    progress: Progress[],
    themeHasAssessment: boolean,
    assessmentCount: number,
  ): boolean {
    const prog = progress.find((p) => p.tag === category.name.toLowerCase() && !p.metadata.savedGame);
    const session = this.getProgressSession(prog, assessmentCount);
    const maxSessions = category.isBonus ? category.startingSession + category.maxSessions : category.maxSessions;

    if (
      session < maxSessions &&
      this.checkCategoryUnlockCondition(category.unlockCondition, progress, themeHasAssessment) &&
      this.canPlayNextSession(prog, category)
    ) {
      return true;
    } else {
      return false;
    }
  }

  public canPlayNextSession(progress: Progress, category: Category): boolean {
    const nextSession = 1000 * 60 * 60 * category.sessionInterval || 0;

    return progress ? Date.now() - new Date(progress.creationTime).getTime() > nextSession : true;
  }

  public createProgressBody(
    exerciseName: string,
    queryData: QueryData,
    progressData: ProgressData,
    unityMessageValues: any,
  ) {
    switch (exerciseName) {
      case GameTag.Assessment: {
        const validExercises = get(unityMessageValues, 'exercises', []).filter((e) => e.name !== VIDEO);
        const level: number = progressData.level;
        const score: number = get(validExercises[0], 'score');
        const records = get(validExercises[0], 'records', []);

        const body = {
          progress: {
            session: queryData.session,
            metadata: {
              level,
              score,
              records,
            },
            studentId: progressData.studentId,
            programId: progressData.programId,
            tag: GameTag.Assessment,
          },
        };

        return body;
      }

      default: {
        const metadata = unityMessageValues;
        metadata.exercises = metadata.exercises.map((exercise) => {
          return {
            name: exercise.name,
            level: progressData.levelCode,
            lang: queryData.lang,
            score: exercise.score,
            session: queryData.session,
            week: queryData.week,
            rawScore: exercise.rawScore,
            bonus: exercise.bonus,
            records: exercise.records || [],
          };
        });

        const body = {
          progress: {
            session: progressData.session,
            metadata,
            studentId: progressData.studentId,
            programId: progressData.programId,
            tag: progressData.tag,
            rerun: progressData.rerun,
          },
        };

        return body;
      }
    }
  }
}
