import { Component, HostListener, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IconDefinition, faUserCog } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ConfirmationService } from 'src/app/services/confirmation/confirmation.service';
import { RestAPIService } from 'src/app/services/rest/rest-api.service';
import { RoleService } from 'src/app/services/roles/role.service';
import { b2cActivationWarning } from 'src/app/shared/consts/global-constants';
import { UpdatesComponent } from '../../../updates/updates.component';
import { Router } from '@angular/router';
import { User } from 'src/app/shared/models';
import { get } from 'lodash';
import { UpdatesHelperService } from 'src/app/services/utils/updates-helper.service';
import { firstValueFrom, Subscription } from 'rxjs';
import { UpdatesControllerService } from 'src/app/core/openapi';

@Component({
  selector: 'header-cogman-menu',
  templateUrl: './cogman-menu.component.html',
  styleUrls: ['./cogman-menu.component.scss'],
})
export class CogmanMenuComponent implements OnInit {
  cogmanIcon: IconDefinition = faUserCog;
  expandSubsections = false;
  totalUpdates = 0;
  handlePasswordUpdated = false;
  isB2CPortal = this._roles.isB2CPortal();
  isOrgClient = this._roles.isOrgClient();
  user: User;

  countSub: Subscription;

  constructor(
    private _roles: RoleService,
    private _auth: AuthService,
    private _confirm: ConfirmationService,
    private _rest: RestAPIService,
    private _snackBar: MatSnackBar,
    private _dialog: MatDialog,
    private _router: Router,
    private _updatesHelper: UpdatesHelperService,
    private _updateService: UpdatesControllerService,
  ) {}

  async ngOnInit(): Promise<void> {
    await this.refreshUpdates();
    this.user = await this._auth.getUser();
    this.handlePasswordUpdated = await this.isPasswordUpdated();
  }

  ngOnDestroy() {
    if (this.countSub) {
      this.countSub.unsubscribe();
    }
  }

  public async askForB2cActivation() {
    try {
      const { organization } = this._auth.getOrgAcc();

      this._confirm.createConfirmation('Warning', b2cActivationWarning, 'Yes', 'No').then(async () => {
        await this._rest.post(`organization/${organization.id}/askForB2cActivation`, {
          msg: 'Could not sent the email',
        });

        this._snackBar.open(
          `The notification has been sent to our administrator and your home version will be activated soon`,
          'Close',
          {
            horizontalPosition: 'center',
            verticalPosition: 'top',
          },
        );
      });
    } catch (error) {
      this._snackBar.open(`Could not send the notification, please try again!`, 'Close', {
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
    }
  }

  public async refreshUpdates() {
    if (this._roles.user) {
      this.countSub = this._updatesHelper.$unreadUpdates.subscribe((count) => (this.totalUpdates = count));
    }
  }

  async toggleUpdates() {
    this.expandSubsections = !this.expandSubsections;

    const updatesList = await firstValueFrom(this._updateService.updatesControllerGetFilteredUpdates());

    this._dialog.open(UpdatesComponent, {
      width: '650px',
      maxHeight: '90vh',
      panelClass: 'custom-dialog-container',
      data: { updatesList, canGoBack: true },
    });
  }

  // Click Handlers --

  public gotoOutsideLink(path) {
    window.open(path, '_blank');
  }

  public logout() {
    this._auth.logout();
  }

  public goToRoute(path: string) {
    this._router.navigate([path]);
  }

  public isCogmanMenuOpen = false;

  @HostListener('click', ['$event'])
  openCogmanMenu(event: any) {
    if (this.isCogmanMenuOpen) {
      this.isCogmanMenuOpen = false;
    } else {
      this.isCogmanMenuOpen = true;
    }
    event.stopPropagation();
  }

  @HostListener('document:click')
  closeCogmanMenu() {
    if (this.isCogmanMenuOpen) {
      this.isCogmanMenuOpen = false;
    }
  }

  async isPasswordUpdated(): Promise<boolean> {
    const user: User = await this._auth.getUser();

    // Guard against user being undefined
    if (user === undefined) {
      return false;
    } else {
      if (user.passwordUpdated !== undefined) {
        return user.passwordUpdated;
      } else {
        return false;
      }
    }
  }

  public pendingRegistration() {
    return get(this.user, 'pendingRegistration', false);
  }
}
