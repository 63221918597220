<div class="main">
  <section class="container">
    <div class="title">
      <h2 *ngIf="typeHttp === 'POST'">Coupon Registration</h2>
      <h2 *ngIf="typeHttp === 'PUT'">Coupon Update</h2>
      <h2 *ngIf="type === 'view' && typeHttp === 'GET'">
        Coupon Visualization
      </h2>
    </div>

    <form class="form">
      <div class="input-group">
        <div class="input-block">
          <label for="couponName"> Name on invoice </label>
          <input
            [disabled]="type === 'view' || typeHttp === 'PUT'"
            name="couponName"
            type="text"
            [(ngModel)]="couponName"
            placeholder="Enter the coupon name displayed to customers"
          />
        </div>
        <div class="input-block">
          <label for="couponId">Insert the name</label>
          <input
            [disabled]="type === 'view' || typeHttp === 'PUT'"
            type="text"
            [(ngModel)]="couponId"
            name="couponId"
            placeholder="Insert the name"
          />
          <button
            [disabled]="type === 'view' || typeHttp === 'PUT'"
            class="btn-primary"
            *ngIf="type !== 'view'"
            (click)="generateCouponCode()"
          >
            Generate
          </button>
        </div>
      </div>

      <div class="input-group">
        <div class="input-block">
          <label for="couponType">Coupon Type</label>
          <select
            [disabled]="type === 'view' || typeHttp === 'PUT'"
            [(ngModel)]="couponType"
            name="couponType"
          >
            <option
              value=""
              disabled
              selected
            >
              Select the type
            </option>
            <option value="amount_off">Discount</option>
            <option value="percent_off">Percentage</option>
          </select>
        </div>
        <div class="input-block">
          <div *ngIf="couponType === 'amount_off'">
            <label for="couponAmountOff">Insert the discount</label>
            <input
              [disabled]="type === 'view' || typeHttp === 'PUT'"
              type="number"
              [(ngModel)]="couponAmountOff"
              name="couponAmountOff"
              placeholder="Insert the discount"
            />
          </div>
          <div *ngIf="couponType === 'percent_off'">
            <label for="couponPercentageOff">Insert the Percentage</label>
            <input
              [disabled]="type === 'view' || typeHttp === 'PUT'"
              type="number"
              [(ngModel)]="couponPercentageOff"
              name="couponPercentageOff"
              placeholder="Insert the percentage"
            />
          </div>
        </div>
      </div>

      <div class="input-block-select">
        <div *ngIf="couponType === 'amount_off'">
          <label for="couponCurrency">Currency</label>
          <select
            [disabled]="type === 'view' || typeHttp === 'PUT'"
            [(ngModel)]="couponCurrency"
            name="couponCurrency"
          >
            <option
              value=""
              disabled
              selected
            >
              Select the currency
            </option>
            <option value="cad">CAD</option>
            <option value="usd">USD</option>
          </select>
        </div>
      </div>

      <div class="input-group">
        <div class="input-block">
          <label for="couponDuration">Duration</label>
          <select
            [disabled]="type === 'view' || typeHttp === 'PUT'"
            [(ngModel)]="couponDuration"
            name="couponDuration"
          >
            <option
              value=""
              disabled
              selected
            >
              Select the type
            </option>
            <option value="forever">Forever</option>
            <option value="once">One time</option>
            <option value="repeating">Repeating</option>
          </select>
        </div>

        <div class="input-block">
          <div *ngIf="couponDuration === 'repeating'">
            <label for="couponDurationInMonths">Duration in months</label>
            <input
              [disabled]="type === 'view' || typeHttp === 'PUT'"
              type="number"
              [(ngModel)]="couponDurationInMonths"
              name="couponDurationInMonths"
              placeholder="Insert the duration in months"
            />
          </div>
        </div>
      </div>

      <div class="input-group">
        <div class="input-block">
          <label for="couponMaxRedemptions">Max redemptions</label>
          <input
            [disabled]="type === 'view' || typeHttp === 'PUT'"
            type="number"
            [(ngModel)]="couponMaxRedemptions"
            name="couponMaxRedemptions"
            placeholder="Insert the max redemptions"
          />
        </div>
        <div class="input-block">
          <label for="couponRedeemBy">Expiration date</label>
          <input
            [disabled]="type === 'view' || typeHttp === 'PUT'"
            type="date"
            [(ngModel)]="couponRedeemBy"
            name="couponRedeemBy"
            placeholder="Insert the expiration date"
          />
        </div>
      </div>
      <div class="button-group">
        <button
          class="btn-secondary"
          (click)="close()"
        >
          Close
        </button>
        <button
          [disabled]="type === 'view' || typeHttp === 'PUT'"
          class="btn-primary"
          (click)="save()"
        >
          Save
        </button>
      </div>
    </form>
  </section>
</div>
