<section class="purchase">
  <h1>Ready to boost your reading journey?</h1>

  <p class="neuralign-is">
    Neuralign is the premium research-based cognitive therapy program for
    dyslexic and struggling readers, and people who are neurodivergent.
  </p>

  <div class="cards">
    <div
      class="title"
      *ngIf="!loading"
    >
      Choose your payment plan.
    </div>

    <div
      *ngIf="!loading"
      class="bundleCards"
    >
      <div
        class="d-flex align-items-center justify-content-center p-3"
        *ngFor="let bundle of priceBundles"
        [ngClass]="{
          card: priceBundles.length > 2,
          singleBundle: priceBundles.length <= 2
        }"
      >
        <div>
          <div
            class="badge"
            *ngIf="!isInstallmentPayment(bundle) && !isSplittedPayment(bundle)"
          >
            <p
              *ngIf="!bundle.subscription"
              class="rounded-outline"
            >
              Best Value! Save {{ splitPaymentAdditionalCost * 100 }}%
            </p>
            <p
              *ngIf="
                bundle.subscription && bundle.paymentType === 'Subscription'
              "
            >
              &nbsp; Subscription
            </p>
          </div>

          <div class="header">
            <div>
              <div class="bundleTitle">
                {{ bundle.title }}
              </div>

              <div
                class="paymentsAmount"
                *ngIf="isInstallmentPayment(bundle)"
              >
                {{ getPaymentsAmount(bundle) }}
              </div>
            </div>
          </div>

          <div class="price">
            <span
              >{{ bundle.currency || 'CAD' }}{{ bundle.currencySymbol || '$' }}
            </span>
            <span class="number">{{ bundle.price | roundUp }}</span>
            <span *ngIf="!isInstallmentPayment(bundle)">
              {{ bundle.subscription ? '/' + bundle.interval : '' }}</span
            >
            <span *ngIf="isInstallmentPayment(bundle)">
              {{
                bundle.interval !== 'year' ? '/' + bundle.interval : ''
              }}</span
            >
          </div>

          <div
            class="subscription"
            *ngIf="
              bundle.subscription &&
              bundle.interval &&
              bundle.paymentType === 'SplittedPayment'
            "
          >
            For {{ bundle.subscriptionPeriod }} {{ bundle.interval }}s ({{
              bundle.currency
            }}{{ bundle.currencySymbol || '$'
            }}{{ bundle.price * bundle.subscriptionPeriod | roundUp }})
          </div>
          <div
            class="subscription"
            *ngIf="bundle.interval && !bundle.subscription"
          >
            {{ bundle.interval }}
          </div>

          <div>
            <app-simple-button
              text="Buy Now"
              smaller="true"
              themeStyle="accent"
              (click)="addToCart(bundle)"
            ></app-simple-button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="p-5 d-flex justify-content-center align-items-center w-100 loading"
      *ngIf="loading"
    >
      <fa-icon
        [icon]="spinnerIcon"
        [spin]="true"
      >
      </fa-icon>
    </div>
  </div>
</section>

<div
  class="warning"
  *ngIf="triggerInstallmentWarning()"
>
  * The Monthly option is a payment plan for the full price of Neuralign, not a
  subscription model. By choosing the Monthly option you are committing to 12
  equal monthly payments.
</div>

<section class="testimonials">
  <h1>Why people like you choose Neuralign</h1>

  <div class="cards">
    <div
      class="card"
      *ngFor="let testimonial of testimonials"
    >
      <h3>{{ testimonial.name }}</h3>
      <p>{{ testimonial.quote }}</p>
      <div class="stars">
        <div class="star">
          <img
            src="assets/icons/star.svg"
            width="100%"
          />
        </div>
        <div class="star">
          <img
            src="assets/icons/star.svg"
            width="100%"
          />
        </div>
        <div class="star">
          <img
            src="assets/icons/star.svg"
            width="100%"
          />
        </div>
        <div class="star">
          <img
            src="assets/icons/star.svg"
            width="100%"
          />
        </div>
        <div class="star">
          <img
            src="assets/icons/star.svg"
            width="100%"
          />
        </div>
      </div>
    </div>
  </div>
</section>
