import { Router } from '@angular/router';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { StripeObservableService } from '../../stripe-observable.service';
import { RestAPIService } from 'src/app/services/rest/rest-api.service';

import {
  StripeDataModal,
  StripeDecimalMultiplier,
  StripeFirstPurchaseType,
  StripeHttpMethod,
  StripePrice,
  StripePriceInterval,
  StripePriceModal,
  StripeProduct,
} from 'src/app/shared/interfaces/Stripe.interfaces';
import { ModalStripeService } from '../modals-stripe.service';

@Component({
  selector: 'app-price-stripe-modal',
  templateUrl: './price-stripe-modal.component.html',
  styleUrls: ['../modal-stripe.component.scss'],
})
export class PriceStripeModalComponent {
  prices: StripePrice[];
  products: StripeProduct[];

  priceId: string;

  // Modal config
  type: string;
  service: string;
  typeHttp: string;
  routeApi: string;

  //Form price
  priceCurrency: string;
  priceUnitAmount: number;
  priceType: string;
  priceActive: boolean;
  priceInterval: StripePriceInterval;
  priceProduct: string;
  priceNickname: string;
  priceQuantityTokens: number;
  priceFirstPurchase: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: StripeDataModal,
    public dialogRef: MatDialogRef<PriceStripeModalComponent>,
    public route: Router,
    private _stripeService: StripeObservableService,
    private _rest: RestAPIService,
    private _modalStripeService: ModalStripeService,
  ) {
    this.type = data.type;
    this.service = data.service;
    this.routeApi = data.routeApi;
    this.typeHttp = data.typeHttp;

    if (data.stripeDataModal) {
      this.initData(data.stripeDataModal.data);
    }
  }

  async ngOnInit() {
    this.products = await this._modalStripeService.getProducts();
  }

  public close() {
    this.dialogRef.close(true);
  }

  private async initData(data: StripePriceModal) {
    this.priceId = data.id;
    this.priceActive = data.priceActive;
    this.priceCurrency = data.priceCurrency;
    this.priceUnitAmount = data.priceUnitAmount / StripeDecimalMultiplier.Hundred;
    this.priceType = data.priceType;
    this.priceInterval = data.priceInterval;
    this.priceNickname = data.priceNickname;
    this.priceQuantityTokens = data.priceQuantityTokens;
    this.priceFirstPurchase = data.priceFirstPurchase === StripeFirstPurchaseType.YES;
    this.priceProduct = await this._modalStripeService.findProductById(data.priceProduct);
  }

  public async save() {
    if (this.typeHttp === StripeHttpMethod.POST) {
      await this.create();
      this.close();
    } else if (this.typeHttp === StripeHttpMethod.PUT) {
      await this.update();
      this.close();
    }
  }

  private async create() {
    try {
      const price = {
        nickname: this.priceNickname,
        currency: this.priceCurrency,
        unit_amount: this.priceUnitAmount * StripeDecimalMultiplier.Hundred,
        recurring: {
          interval: this.priceInterval,
        },
        product: this.priceProduct,
        active: this.priceActive,
        metadata: {
          tokens: this.priceQuantityTokens,
          first_purchase: this.priceFirstPurchase === true ? StripeFirstPurchaseType.YES : StripeFirstPurchaseType.NO,
        },
      };
      const createPrice = await this._rest.post(this.routeApi, price);
      this._stripeService.changePrice(createPrice);
    } catch (error) {
      throw new Error(error);
    }
  }

  private async update() {
    try {
      const updatePrice = await this._rest.put(`${this.routeApi}/id/${this.priceId}`, {
        nickname: this.priceNickname,
        active: this.priceActive,
        metadata: {
          tokens: this.priceQuantityTokens,
          first_purchase: this.priceFirstPurchase === true ? StripeFirstPurchaseType.YES : StripeFirstPurchaseType.NO,
        },
      });
      this._stripeService.changePrice(updatePrice);
    } catch (error) {
      throw new Error(error);
    }
  }
}
