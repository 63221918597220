<!-- Host -->
<app-main-layout
  [breadcrumbs]="[
    { name: 'Home', url: '/' },
    { name: 'Users', url: '/users' }
  ]"
>
  <header>
    <users-tabs (menuToDisplay)="switchMenu($event)"></users-tabs>

    <div class="actions">
      <fa-icon
        class="pointer icon"
        [icon]="question"
        (click)="openWelcomeVideo()"
        matTooltip="Open welcome video"
        matTooltipPosition="above"
      >
      </fa-icon>

      <app-import-export></app-import-export>

      <users-add-new-button
        (onAddNewUser)="handleAddNewUser()"
      ></users-add-new-button>
    </div>
  </header>

  <app-tutorial-modal
    *ngIf="showTutorial"
    [callback]="onCompleteTutorial"
    [tutorial]="tutorial"
    [isSingle]="isSingle"
  >
  </app-tutorial-modal>

  <!-- Templates -->

  <div
    *ngIf="template === 'privacy'"
    @fadeIn
  >
    <users-privacy-screen></users-privacy-screen>
  </div>

  <div
    *ngIf="template === 'clients'"
    @fadeIn
  >
    <users-clients-menu></users-clients-menu>
  </div>

  <div
    *ngIf="template === 'b2c'"
    @fadeIn
  >
    <users-b2c-menu
      [userHasStripeAccount]="userHasStripeAccount"
      [b2cActivationStatus]="b2cAllowSignupStatus"
      [isLoadingActivationStatusChange]="isLoadingActivationStatusChange"
      (toggleB2CSignup)="handleToggleB2CSignup()"
    ></users-b2c-menu>
  </div>

  <div
    *ngIf="template === 'organizations'"
    @fadeIn
  >
    <users-organizations-menu
      [userHasStripeAccount]="userHasStripeAccount"
    ></users-organizations-menu>
  </div>

  <div
    *ngIf="template === 'managersAndAdmins'"
    @fadeIn
  >
    <users-managers-and-admins-menu></users-managers-and-admins-menu>
  </div>

  <div
    *ngIf="template === 'new-user'"
    @fadeIn
  >
    <app-manage-user></app-manage-user>
  </div>
</app-main-layout>
