import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { StripeObservableService } from '../stripe-observable.service';
import { RestAPIService } from 'src/app/services/rest/rest-api.service';
import { StripeCoupon, StripeDecimalMultiplier } from 'src/app/shared/interfaces/Stripe.interfaces';

@Component({
  selector: 'app-coupon-stripe',
  templateUrl: './coupon-stripe.component.html',
  styleUrls: ['./coupon-stripe.component.scss'],
})
export class CouponStripeComponent implements OnInit {
  public coupons: StripeCoupon[];
  public originalCoupons: StripeCoupon[];
  public routeApi = 'stripe/coupon';

  searchTerm: string;

  constructor(
    private _rest: RestAPIService,
    public dialog: MatDialog,
    private _stripeService: StripeObservableService,
    public _snackBar: MatSnackBar,
  ) {}

  async ngOnInit() {
    await this.getCoupons();
    this.originalCoupons = Array.isArray(this.coupons) ? [...this.coupons] : [];
    this._stripeService.currentCoupon.subscribe(async (newCoupon) => {
      if (newCoupon) {
        await this.handleTableCoupons(newCoupon);
      }
    });
    this.search();
  }

  async handleTableCoupons(newCoupon: StripeCoupon) {
    if (newCoupon.valid === false) {
      this.coupons = this.coupons.filter((coupon: StripeCoupon) => coupon.id !== newCoupon.id);
    } else {
      const existingCouponIndex = this.coupons.findIndex((coupon: StripeCoupon) => coupon.id === newCoupon.id);

      if (existingCouponIndex !== -1) {
        this.coupons[existingCouponIndex] = newCoupon;
      } else {
        const formattedCoupon = this.formattedCoupons([newCoupon]);
        this.coupons.push(...formattedCoupon);
      }
    }
    this.originalCoupons = [...this.coupons];
    this.search();
  }

  async getCoupons() {
    try {
      const coupons = await this._rest.get(`${this.routeApi}/active`);
      const formattedCoupons = this.formattedCoupons(coupons);
      this.coupons = formattedCoupons;
    } catch (error) {
      this._snackBar.open('Error loading coupons', 'Close', {
        duration: 2000,
      });
    }
  }

  formattedCoupons(coupons) {
    const DEFAULT_VALUE = '-';
    const copyCoupons = [...coupons];

    const formattedCoupons = copyCoupons.map((coupon) => {
      return {
        ...coupon,
        amount_off: coupon.amount_off ? coupon.amount_off / StripeDecimalMultiplier.Hundred : DEFAULT_VALUE,
        percent_off: coupon.percent_off ? coupon.percent_off : DEFAULT_VALUE,

        duration_in_months: coupon.duration_in_months ? coupon.duration_in_months : DEFAULT_VALUE,
      };
    });

    return formattedCoupons;
  }

  async deleteCoupons(id: string) {
    try {
      await this._rest.delete(`${this.routeApi}/id/${id}`);
      this.coupons = this.coupons.filter((coupon) => coupon.id !== id);
      this._snackBar.open('Coupon deleted successfully', 'Close', {
        duration: 2000,
      });
    } catch (error) {
      this._snackBar.open('Error deleting coupon', 'Close', {
        duration: 2000,
      });
    }
  }

  search() {
    this.coupons = this.handleCouponsSearch(this.originalCoupons, this.searchTerm);
  }

  public handleCouponsSearch(items: any, searchTerm: string) {
    if (!items) return [];
    if (!searchTerm) return items;

    searchTerm = searchTerm.toLowerCase();

    return items.filter((it) => {
      return (
        (it.id ? it.id.toLowerCase().includes(searchTerm) : false) ||
        (it.name ? it.name.toLowerCase().includes(searchTerm) : false) ||
        (it.percent_off ? it.percent_off.toString().includes(searchTerm) : false) ||
        (it.amount_off ? it.amount_off.toString().includes(searchTerm) : false) ||
        (it.duration ? it.duration.toString().includes(searchTerm) : false) ||
        (it.max_redemptions ? it.max_redemptions.toString().includes(searchTerm) : false)
      );
    });
  }
}
